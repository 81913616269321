import React, { Component } from "react";
import ReactGA from "react-ga";
import $ from "jquery";
import "./App.css";

import HomePage from "./Components/Home";
//Dance to the music
import Header from "./Components/dancetothemusic/Header";
import Footer from "./Components/dancetothemusic/Footer";
import Info from "./Components/dancetothemusic/Info";
import Workshop from "./Components/dancetothemusic/Workshop";
import Stage from "./Components/dancetothemusic/Stage";
import Battle from "./Components/dancetothemusic/Battle";
import Coreo from "./Components/dancetothemusic/Coreo";
import Guests from "./Components/dancetothemusic/Guests";
import Header_iscrizioni from "./Components/dancetothemusic/Iscrizioni/Header_iscrizioni";
import Iscrizioni_stage from "./Components/dancetothemusic/Iscrizioni/Iscrizioni_stage";
import Iscrizioni_coreografico from "./Components/dancetothemusic/Iscrizioni/Iscrizioni_coreografico";
import Iscrizioni_battle from "./Components/dancetothemusic/Iscrizioni/Iscrizioni_battle";

//Centro Estivo
import HeaderCE from "./Components/centroestivo/Header";
import InfoCE from "./Components/centroestivo/Info";
import FooterCE from "./Components/centroestivo/Footer";
import Date_ce from "./Components/centroestivo/Date";
import Attivita from "./Components/centroestivo/Attivita";
import Programma from "./Components/centroestivo/Programma";
import Contatti from "./Components/centroestivo/Contact";

//OrdinaryLab
import LabChoice from "./Components/ordinarylab/LabChoice";
import HeaderLab from "./Components/ordinarylab/hip_hop/Header";
import AboutLab from "./Components/ordinarylab/hip_hop/About";
import Chisiamo from "./Components/ordinarylab/Chisiamo";
import Prenota from "./Components/ordinarylab/hip_hop/Prenota";
import FooterLab from "./Components/ordinarylab/Footer";
import Media from "./Components/ordinarylab/Media";
import EventiHH from "./Components/ordinarylab/hip_hop/Eventi";

import HeaderHouse from "./Components/ordinarylab/house/Header";
import AboutHouse from "./Components/ordinarylab/house/About";
import PrenotaHouse from "./Components/ordinarylab/house/Prenota";

//Cypher Workshop
import HeaderCW from "./Components/cyperworkshop/Header";
import WorkshopCW from "./Components/cyperworkshop/Workshop";
import Header_iscrizioniCW from "./Components/cyperworkshop/Iscrizioni/Header_iscrizioni";
import Iscrizioni_workshop from "./Components/cyperworkshop/Iscrizioni/Iscrizioni_workshop";
import MediaCW from "./Components/cyperworkshop/Media";
import InfoCW from "./Components/cyperworkshop/Info";
import FooterCW from "./Components/cyperworkshop/Footer";
/*
//Music
import HeaderM from "./Components/music/Header";
import Bandcamp from "./Components/music/Bandcamp";
import ContattiM from "./Components/music/Contact";
import FooterM from "./Components/music/Footer";
*/
import { BrowserRouter, Route, Routes } from 'react-router-dom';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foo: "bar",
      resumeData: {}
    };

    ReactGA.initialize("UA-110570651-1");
    ReactGA.pageview(window.location.pathname);
  }

  getResumeData() {
    $.ajax({
      url: "/resumeData.json",
      dataType: "json",
      cache: false,
      success: function (data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function (xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
  }

  componentDidMount() {
    this.getResumeData();
  }

  render() {
    const Home = () => {
      return (
        <div className="App">
          <HomePage data={this.state.resumeData.main} />
        </div>
      );
    };

    {/* DANCE TO THE MUSIC ROUTES*/ }
    const Dancetothemusic = () => {
      return (
        <div className="App">
          <Header data={this.state.resumeData.main} />
          {/*<Workshop data={this.state.resumeData.main} /> */}
          
          <Coreo data={this.state.resumeData.main} />
          <Battle data={this.state.resumeData.main}/>
          <Stage data={this.state.resumeData.main}/>
          {/*<Guests data={this.state.resumeData.main}/>*/}
          <Info data={this.state.resumeData.main} />
          <Footer data={this.state.resumeData.main} />
        </div>
      );
    };

    const IscrizioniBattle = () => {
      return (
        <div className="App">
          <Header_iscrizioni data={this.state.resumeData.main} />
          <Iscrizioni_battle data={this.state.resumeData.main} />
        </div>
      );
    };

    const IscrizioniCoreografico = () => {
      return (
        <div className="App">
          <Header_iscrizioni data={this.state.resumeData.main} />
          <Iscrizioni_coreografico data={this.state.resumeData.main} />
        </div>
      );
    };

    const IscrizioniStage = () => {
      return (
        <div className="App">
          <Header_iscrizioni data={this.state.resumeData.main} />
          <Iscrizioni_stage data={this.state.resumeData.main} />
        </div>
      );
    };
    {/* END DANCE TO THE MUSIC ROUTES*/ }

    {/* CENTRO ESTIVO ROUTES*/ }
    const Centroestivo = () => {
      return (
        <div className="App">
          <HeaderCE data={this.state.resumeData.main} />
          <Date_ce data={this.state.resumeData.main} />
          <Attivita data={this.state.resumeData.main} />
          {/* <Programma data={this.state.resumeData.main} />*/}
          <InfoCE data={this.state.resumeData.main} />
          <Contatti data={this.state.resumeData.main} />
          <FooterCE data={this.state.resumeData.main} />
        </div>
      );
    };
    {/* END CENTRO ESTIVO ROUTES*/ }

    {/* ORDINARYLAB ROUTES*/ }
    const OrdinaryLab = () => {
      return (
        <div className="App">
          <LabChoice data={this.state.resumeData.main} />
        </div>
      );
    };
    {/* END ORDINARYLAB ROUTES*/ }

    {/* ORDINARYLAB HIP HOP ROUTES*/ }
    const OrdinaryLabHipHop = () => {
      return (
        <div className="App">
          <HeaderLab data={this.state.resumeData.main} />
          <AboutLab data={this.state.resumeData.main} />
          <Chisiamo data={this.state.resumeData.main} />
          <EventiHH data={this.state.resumeData.main} />
          <Prenota data={this.state.resumeData.main} />
          <Media data={this.state.resumeData.main} />
          <FooterLab data={this.state.resumeData.main} />
        </div>
      );
    };
    {/* END ORDINARYLAB HIP HOP ROUTES*/ }

    {/* ORDINARYLAB HOUSE ROUTES*/ }
    const OrdinaryLabHouse = () => {
      return (
        <div className="App">
          <HeaderHouse data={this.state.resumeData.main} />
          <AboutHouse data={this.state.resumeData.main} />
          <Chisiamo data={this.state.resumeData.main} />
          <PrenotaHouse data={this.state.resumeData.main} />
          <Media data={this.state.resumeData.main} />
          <FooterLab data={this.state.resumeData.main} />
        </div>
      );
    };
    {/* END ORDINARYLAB HOUSE ROUTES*/ }

    {/* CYPHER WORKSHOP */}
    const CypherWorkshop = () => {
      return (
        <div className="App">
          <HeaderCW data={this.state.resumeData.main} />
          <WorkshopCW data={this.state.resumeData.main} />
          <MediaCW data={this.state.resumeData.main} />
          <InfoCW data={this.state.resumeData.main} />
          <FooterCW data={this.state.resumeData.main} />
        </div>
      );
    };

    const IscrizioniWorkshop = () => {
      return (
        <div className="App">
          <Header_iscrizioniCW data={this.state.resumeData.main} />
          <Iscrizioni_workshop data={this.state.resumeData.main} />
        </div>
      );
    };
    {/* END CYPHER WORKSHOP */}

    {/* MUSIC ROUTES
    const Musica = () => {
      return (
      <div className="App">
        <HeaderM data={this.state.resumeData.main} />
        <Bandcamp data={this.state.resumeData.main} />
        <ContattiM data={this.state.resumeData.main} />
        <FooterM data={this.state.resumeData.main} />
      </div>
      );
    };
    {/* END MUSIC ROUTES*/}

    return (
      <BrowserRouter>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dancetothemusic" element={<Dancetothemusic />} />
            {/*<Route path="/dancetothemusic_iscrizioni_battle" element={<IscrizioniBattle />} />
            <Route path="/dancetothemusic_iscrizioni_stage" element={<IscrizioniStage />} />
            {/*<Route path="/dancetothemusic_iscrizioni_coreo" element={<IscrizioniCoreografico />} />*/}
            <Route path="/centroestivo" element={<Centroestivo />} />
            <Route path="/ordinary_lab" element={<OrdinaryLab />} />
            <Route path="/ordinary_lab_hip_hop" element={<OrdinaryLabHipHop />} />
            <Route path="/ordinary_lab_house" element={<OrdinaryLabHouse />} />
            <Route path="/cypherworkshop" element={<CypherWorkshop />} />

            <Route path="/cypherworkshop/iscrizioni" element={<IscrizioniWorkshop />} />
            {/*<Route path="/music" element={<Music />} />*/}
          </Routes>

          
        </div>

      </BrowserRouter>
    );
  }
}

export default App;
