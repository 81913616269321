import React, { Component } from "react";
import Fade from "react-reveal";

class Header extends Component {
  render() {
    if (!this.props.data) return null;

    const description = this.props.data.description;
    const date = this.props.data.date;
    const logo = "images/dttm/" + this.props.data.logo;
    return (
      <header id="home" >
      
       {/* <ParticlesBg type="circle" bg={true} /> */}

        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="">
              <a className="" href="/">
                Homepage
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#coreo">
                Coreografico
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#battle">
                Battle
              </a>
            </li>
            
        
            <li>
              <a className="smoothscroll" href="#stage">
                Stage
              </a>
            </li>
            

            
            

         {/*   <li>
              <a className="smoothscroll" href="#resume">
                Resume
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#portfolio">
                Works
              </a>
            </li>
    */}
            <li>
              <a className="smoothscroll" href="#contact">
                Contatti
              </a>
            </li>
          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text">
            <Fade bottom>
              <img
                className="profile-pic"
                src={logo}
                style={{height:"400px"}}
                alt="Dance to the music logo"
              />
            </Fade>
            <Fade bottom duration={1200} >
              <div className="headerDttm">
                <h2 className="textShadowBlack">{date}</h2>
                <br/>
                <h2 className="textShadowBlack" style={{fontSize:"25px"}}>Choreographic Contest <br/> Battle to the music<br/> Stage</h2>
              </div>
            </Fade>
            
            
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#coreo">
            <i className="icon-down-circle"></i>
          </a>
        </p>
        
      </header>
    );
  }
}

export default Header;
