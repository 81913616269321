import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";

class Info extends Component {
    render() {
        if (!this.props.data) return null;

        const whatsapp = this.props.data.whatsapp;
        const email = "mailto:" + this.props.data.ordinary_lab.mail;
        const instagram = this.props.data.ordinary_lab.instagram_marco;
        const telegram = this.props.data.telegram;
        const vimeo = this.props.data.ordinary_lab.vimeo_marco;

        const whatsapp_2 = this.props.data.whatsapp_2;
        const instagram_2 = this.props.data.instagram_2;

        const logo_whatsapp = "images/" + this.props.data.loghi.whatsapp;
        const logo_email = "images/" + this.props.data.loghi.email;
        const logo_telegram = "images/" + this.props.data.loghi.telegram;
        const logo_instagram = "images/" + this.props.data.loghi.instagram;
        const logo_facebook = "images/" + this.props.data.loghi.facebook;
        const logo_vimeo = "images/" + this.props.data.loghi.vimeo_round;

        //<section id="contact" style={{background:"url(images/info_background.png)" }}>

        return (


            <section id="contact" style={{ background: "url(images/cypher_workshop/bkground_info.png)", backgroundSize: "cover" }}>
                <div className="row">
                    <Slide right duration={1000}>
                        {/*    <aside className="four columns footer-widgets" style={{ background: "#ffffff98" }}>
                            <div className="widget widget_contact">
                                <h3>Info e Contatti</h3>
                                <p className="address" style={{ color: "#dbdddd" }} >
                                    <div className="row" style={{ marginTop: "10px" }}>
                                        <div className="eight columns">
                                            <a className="social-links" target="blank" style={{ marginRight: "15px" }} href={whatsapp}>
                                                <img src={logo_whatsapp} style={{ width: "20%" }} />
                                            </a>
                                            <a target="blank" style={{ marginRight: "15px" }} href={email}>
                                                <img src={logo_email} style={{ width: "20%" }} />
                                            </a>
                                            <a target="blank" style={{ marginRight: "15px" }} href={telegram}>
                                                <img src={logo_telegram} style={{ width: "20%" }} />
                                            </a>
                                        </div>
                                    </div>
                                </p>
                            </div>
                            <div className="widget widget_contact">
                                <h3>Social</h3>
                                <p className="address" style={{ color: "#dbdddd" }} >
                                    <div className="row" style={{ marginTop: "10px" }}>
                                        <div className="eight columns">
                                            <a className="social-links" target="blank" style={{ marginRight: "15px" }} href={instagram}>
                                                <img src={logo_instagram} style={{ width: "20%" }} />
                                            </a>
                                            <a target="blank" style={{ marginRight: "15px" }} href={facebook}>
                                                <img src={logo_facebook} style={{ width: "20%" }} />
                                            </a>

                                        </div>
                                    </div>
                                </p>
                            </div>

        </aside> */}
                        <h1 className="text-center">Info e Contatti</h1>
                        <div className="twelve columns footer-widgets text-center" style={{ paddingBottom: "30px" }}>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2788.806132432!2d11.756568876944318!3d45.65471487107765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4778d46de4809993%3A0xfa0c55419764565b!2sPegaso%20Fitness%20e%20Wellness!5e0!3m2!1sit!2sit!4v1706127649459!5m2!1sit!2sit" 
                                className="maps" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <Fade bottom>
                            <div className="row">
                                <div className="twelve columns">
                                    <h6 className="text-center" style={{ color: "#fff" }}> MARCO </h6>
                                    <ul className="social-links text-center" >
                                        <a className="social-links" target="blank" style={{ marginRight: "15px" }} href={whatsapp}>
                                            <img alt="whatsapp" src={logo_whatsapp} style={{ width: "45px" }} />
                                        </a>
                                        <a target="blank" style={{ marginRight: "15px" }} href={telegram}>
                                            <img alt="telegram" src={logo_telegram} style={{ width: "45px" }} />
                                        </a>
                                        <a target="blank" style={{ marginRight: "15px" }} href={email}>
                                            <img alt="email" src={logo_email} style={{ width: "45px" }} />
                                        </a>
                                        <a className="social-links" target="blank" style={{ marginRight: "15px" }} href={instagram}>
                                            <img alt="instagram" src={logo_instagram} style={{ width: "45px" }} />
                                        </a>
                                        <a target="blank" style={{ marginRight: "15px" }} href={vimeo}>
                                            <img alt="facebook" src={logo_vimeo} style={{ width: "40px", marginBottom: "5px" }} />
                                        </a>
                                    </ul>
                                </div>
                            </div>

                            <div className="row">
                                <div className="twelve columns">
                                    <h6 className="text-center" style={{ color: "#fff" }}> WALLY </h6>
                                    <ul className="social-links text-center" >
                                        <a className="social-links" target="blank" style={{ marginRight: "15px" }} href={whatsapp_2}>
                                            <img alt="whatsapp" src={logo_whatsapp} style={{ width: "45px" }} />
                                        </a>
                                        <a className="social-links" target="blank" style={{ marginRight: "15px" }} href={instagram_2}>
                                            <img alt="instagram" src={logo_instagram} style={{ width: "45px" }} />
                                        </a>
                                    </ul>
                                </div>
                            </div>
                        </Fade>


                    </Slide>
                </div>
            </section>
        );
    }
}

export default Info;
