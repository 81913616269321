import React, { Component } from "react";
import Fade from "react-reveal";
import $ from 'jquery';

class Chisiamo extends Component {

    showMore() {
        $('#bio_expand').hide();
        $('#p_more_bio').show();
        $('#bio_reduce').show();
    }

    showLess() {
        $('#bio_expand').show();
        $('#p_more_bio').hide();
        $('#bio_reduce').hide();
    }


    render() {
        if (!this.props.data) return null;

        const hiphop_desc = this.props.data.ordinary_lab.hiphop_desc;
        const foto_marco = "images/" + this.props.data.ordinary_lab.foto_marco;
        const foto_collo = "images/" + this.props.data.ordinary_lab.foto_collo;
        const marco_bio = this.props.data.ordinary_lab.marco_bio;
        const marco_bio_short = this.props.data.ordinary_lab.marco_bio_short;
        const marco_bio_more = this.props.data.ordinary_lab.marco_bio_more;
        const collo_bio = this.props.data.ordinary_lab.collo_bio;
        const instagram_marco = this.props.data.ordinary_lab.instagram_marco;
        const instagram_collo = this.props.data.ordinary_lab.instagram_collo;
        const vimeo_marco = this.props.data.ordinary_lab.vimeo_marco;
        
        const logo_instagram = "images/" + this.props.data.loghi.instagram;
        const logo_vimeo = "images/" + this.props.data.loghi.vimeo;


        return (
            <section id="resume">
                <Fade duration={1000}>
                    <div className="row align-center">
                        < div className="align-center">
                            <h1>
                                <span style={{ color: "black" }}><u>CHI SIAMO?</u></span>
                            </h1>
                        </div>
                    </div>

                    <div className="resume_web" id="resume_web">
                    <div className="row">
                        <div className="three columns main-col" >
                            <img src={foto_marco} alt="Marco Cavalloro" style={{ "max-width": "150%", "padding-top":"50px" }} />
                            
                        </div>
                        <div className="nine columns main-col" style={{"text-align":"right"}}>
                            <h2>Marco Cavalloro</h2>
                            <p>{marco_bio} 
                            <br/><a className="social-links" target="blank" style={{ marginRight: "15px" }} href={instagram_marco}>
                                <img alt="instagram" src={logo_instagram} style={{ width: "45px" }} />
                            </a>
                            <a className="social-links" target="blank" style={{ marginRight: "15px" }} href={vimeo_marco}>
                                <img alt="vimeo" src={logo_vimeo} style={{ width: "45px" }} />
                            </a>
                            </p>
                        </div>

                    </div>

                    <div className="row">
                        <div className="nine columns main-col">
                            <h2>Dj Collo</h2>
                            <p>{collo_bio}
                            <br/><a className="social-links" target="blank" style={{ marginRight: "15px" }} href={instagram_collo}>
                                <img alt="instagram" src={logo_instagram} style={{ width: "45px" }} />
                            </a>
                            </p>
                        </div>
                        <div className="three columns main-col">
                            <img src={foto_collo} alt="Dj Collo" style={{ "max-width": "150%", "padding-top":"50px" }} />
                        </div>
                        
                    </div>
                    </div>


                    <div className="resume_mobile" id="resume_mobile">
                    <img src={foto_marco} alt="Marco Cavalloro" style={{ "max-width": "80%", "padding-top":"50px", "float":"right", "margin-right":"-90px"}} />
                    
                    <div className="row">
                        <div className="nine columns main-col" >
                            <h2>Marco Cavalloro</h2>
                            <p>{marco_bio_short} 
                            <br/><button type="text" id="bio_expand" onClick={this.showMore}>Continua..</button>
                            <p id="p_more_bio" style={{"display":"none"}}>{marco_bio_more}</p>
                            <button type="text" id="bio_reduce" onClick={this.showLess} style={{"display":"none"}}>Mostra meno..</button>
                            
                            <br/><a className="social-links" target="blank" style={{ marginRight: "15px" }} href={instagram_marco}>
                                <img alt="instagram" src={logo_instagram} style={{ width: "45px" }} />
                            </a>
                            <a className="social-links" target="blank" style={{ marginRight: "15px" }} href={vimeo_marco}>
                                <img alt="vimeo" src={logo_vimeo} style={{ width: "45px" }} />
                            </a>
                            </p>
                        </div>

                    </div>

                    <img src={foto_collo} alt="Dj Collo" style={{ "max-width": "80%", "padding-top":"50px", "float":"right", "margin-right":"-90px"}} />
                    <div className="row">
                        <div className="nine columns main-col">
                            <h2>Dj Collo</h2>
                            <p>{collo_bio}
                            <br/><a className="social-links" target="blank" style={{ marginRight: "15px" }} href={instagram_collo}>
                                <img alt="instagram" src={logo_instagram} style={{ width: "45px" }} />
                            </a>
                            </p>
                        </div>
                        
                        
                    </div>
                    </div>


                </Fade>
            </section>
        );
    }
}

export default Chisiamo;
