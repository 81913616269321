import React, { Component } from "react";
import Fade from "react-reveal";

class Footer extends Component {
  render() {
    if (!this.props.data) return null;

/*    const whatsapp = this.props.data.whatsapp;
    const email = "mailto:" + this.props.data.email;
    const instagram = this.props.data.instagram;
    const facebook = this.props.data.facebook;
    const telegram = this.props.data.telegram;

    const logo_whatsapp = "images/" + this.props.data.loghi.whatsapp;
    const logo_email = "images/" + this.props.data.loghi.email;
    const logo_telegram = "images/" + this.props.data.loghi.telegram;
    const logo_instagram = "images/" + this.props.data.loghi.instagram;
    const logo_facebook = "images/" + this.props.data.loghi.facebook;
*/
    const networks = this.props.data.social.map(function (network) {
      return (
        <li key={network.name}>
          <a href={network.url}>
            <i className={network.className}></i>
          </a>
        </li>
      );
    });

    return (
      <footer>
        <div className="row">
          <Fade bottom>
           </Fade>

          <div id="go-top">
            <a className="smoothscroll" title="Back to Top" href="#home_cypher">
              <i className="icon-up-open"></i>
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
