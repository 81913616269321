import React, { Component } from "react";
import Fade from "react-reveal";
import { ZoomImage } from "../../ZoomImage";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const profilepic = "images/" + this.props.data.image;
    const bio = this.props.data.bio;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const email = this.props.data.email;
    const resumeDownload = this.props.data.resumedownload;

    const general_desc = this.props.data.ordinary_lab.general_desc;
    const hiphop_desc = this.props.data.ordinary_lab.hiphop_desc;
    const hiphop_photo = "images/" + this.props.data.ordinary_lab.choice_hip_hop;

    return (
      <section id="about">
        <Fade duration={1000}>
        <div className="row align-center">
            < div className="align-center">
                <h1>
                    <span style={{ color: "white" }} className="shadowtext-1b"><u>ABOUT</u></span>
                </h1>
            </div>
        </div>

        <div className="row">
            <div className="nine columns main-col">
                <h2>Cosa è Ordinary LAB?</h2>
                <p id="general_desc">Ordinary Lab è un laboratorio ideato da <b>Marco Cavalloro</b> e <b>dj Collo</b>. L'unione di esercizi mirati, ascolto musicale e nozioni culturali sono il <b>punto di forza</b> di questa proposta che vuole guidare i partecipanti a fondere maggiormente musica e danza nel proprio movimento. <b>Marco</b> si occuperà della danza proponendo metodi di allenamento esercizi tecnici e nozioni sulla didattica. <b>Collo</b> invece della parte musicale attraverso ascolto e analisi di una traccia sia nell'aspetto tecnico che culturale.</p>
            </div>
            <div className="three columns main-col"></div>
        </div>

        <div className="row">
        <div className="three columns main-col">
            <ZoomImage className="img_iscrizioni" title="" largeImage={hiphop_photo} smallImage={hiphop_photo} />
        </div>
            
            <div className="nine columns main-col">
                <h2>Percorso Hip Hop</h2>
                <p>Per l'Ordinary Hip Hop Lab partiremo dall'ascolto di alcuni beat "scomposti", andando così ad ascoltare le componenti musicali principali della traccia per studiare la tipologia di movimenti che potrebbero essere adatti a certe sonorità: isolazioni, variazioni, waves, foundations, strutture, concetti... 
La combinazione di esercizi di ascolto ed esercizi tecnici e pratici ha l'obiettivo di arrivare ad una comprensione migliore della musica e di se stessi per arrivare ad una musicalità più naturale della propria danza.
                </p>
            </div>
        </div>
        </Fade>
      </section>
    );
  }
}

export default About;
