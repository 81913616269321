import React, { Component } from "react";

class Header_iscrizioniCW extends Component {
  render() {
    if (!this.props.data) return null;

    return (

        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="">
              <a className="" href="/cypherworkshop">
                Cypher Workshop
              </a>
            </li>

          </ul>
        </nav>
    );
  }
}

export default Header_iscrizioniCW;
