import React, { Component } from "react";
import Fade from "react-reveal";
import { Attivita_section1 } from "./Attivita_section1";
import { Attivita_section2 } from "./Attivita_section2";
import { Attivita_section3 } from "./Attivita_section3";
import { Attivita_section4 } from "./Attivita_section4";

class Attivita extends Component {
    state = { isOpen: false };

    handleShowDialog = () => {
        this.setState({ isOpen: !this.state.isOpen });
        console.log("cliked");
    };

    render() {
        if (!this.props.data) return null;

        const loghi = "images/centroestivo/loghi.png";

        return (
            <section id="attivita" className="attivita_ce">
                <Fade duration={1000}>
                    <div className="row">
                        < div className="align-center">
                            <h1>
                                <span style={{ color: "white" }} className="shadowtext-1b">ATTIVITÀ</span>
                            </h1>
                            <h5 style={{color:"white", paddingLeft:"25px", paddingRight:"25px"}}>Le attività saranno differenziate in base alla fascia d'età<br/> (6-9 anni e 10-12 anni)</h5>
                        </div>
                    </div>
                    <br />

                    <div className="row" id="attivita_mobile">
                        <div className="twelve columns align-center">
                            <div id="attivita_slide" className="carousel slide" data-bs-ride="carousel">

                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#attivita_slide" data-bs-slide-to="0" className="active"></button>
                                    <button type="button" data-bs-target="#attivita_slide" data-bs-slide-to="1"></button>
                                    <button type="button" data-bs-target="#attivita_slide" data-bs-slide-to="2"></button>
                                    <button type="button" data-bs-target="#attivita_slide" data-bs-slide-to="3"></button>
                                </div>

                                <div className="carousel-inner">
                                    <div className="carousel-item active attivita-list" >
                                        <Attivita_section1/>
                                    </div>
                                    <div className="carousel-item attivita-list">
                                        <Attivita_section2/>
                                    </div>
                                    <div className="carousel-item attivita-list">
                                        <Attivita_section3/>
                                    </div>
                                    <div className="carousel-item attivita-list">
                                        <Attivita_section4/>
                                    </div>
                                </div>

                                <button className="carousel-control-prev" type="button" data-bs-target="#attivita_slide" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon"></span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#attivita_slide" data-bs-slide="next">
                                    <span className="carousel-control-next-icon"></span>
                                </button>
                            </div>
                        </div>
                    </div>


                    <div id="attivita_web">

                    <div className="row">
                            <div className="three columns p-0"></div>
                            < div className="six columns align-center p-0" style={{"margin-top":"-23px"}}>
                                <h3> <img src={"images/centroestivo/loghi/logo_masa.png"} width="20%"/> </h3><h3 className="shadowtext-1bl">Battesimo della canoa</h3>
                            </div>
                            <div className="three columns p-0"></div>
                        </div>

                        <div className="row">
                            < div className="four columns align-center p-0" style={{"margin-top":"-23px"}}>
                                <h3><img src={"images/centroestivo/loghi/logo_sportmix2.png"} width="13%"/></h3><h3 className="shadowtext-1bl">Psicomotricità, <br/>Giochi di movimento, <br/>Giochi di squadra</h3>
                            </div>
                            < div className="four columns align-center p-0" style={{"margin-top":"-30px"}}>
                                <h3> <img src={"images/centroestivo/loghi/logo_cambusiero.png"} width="15%"/> </h3><h3 className="shadowtext-1bl">Mare, <br/> giochi in spiaggia, <br/> attività in pineta</h3>
                            </div>
                            
                        </div>

                        <div className="row">
                            < div className="twelve columns align-center p-0" style={{"margin-top":"-20px"}}>
                                <h3> <img src={"images/centroestivo/loghi/logo_molozero.jpg"} width="5%"/> </h3><h3 className="shadowtext-1bl">Capitano per un giorno</h3>
                                <h6 className="new-badge align-center">NEW</h6>
                            </div>
                        </div>

                        <div className="row">
                            < div className="four columns align-center p-0" style={{"margin-top":"-20px"}}>
                                <h3><img src={"images/centroestivo/loghi/logo_bike.png"} width="13%"/></h3><h3 className="shadowtext-1bl">Mountain Bike</h3>
                                <h6 className="new-badge align-center">NEW</h6>
                            </div>
                            < div className="four columns align-center p-0" style={{"margin-top":"-20px"}}>
                                <h3><img src={"images/centroestivo/loghi/logo_pasticceria.jpeg"} width="13%"/></h3><h3 className="shadowtext-1bl">Pasticciando</h3>
                                <h6 className="new-badge align-center">NEW</h6>
                            </div>
                        </div>
                        

                        <div className="row">
                            <div className="three columns p-0"></div>
                            < div className="six columns align-center p-0" >
                                <h3> <img src={"images/centroestivo/loghi/logo_ortostorto.png"} width="20%" style={{"padding-right":"10px"}}/><img src={"images/centroestivo/loghi/logo_alicenova.png"} width="15%"/> </h3><h3 className="shadowtext-1bl">Attività Didattiche</h3>
                            </div>
                            <div className="three columns p-0"></div>
                        </div>

                        <div className="row">
                            < div className="four columns align-center p-0" style={{"margin-top":"-20px"}}>
                                <h3><img src={"images/centroestivo/loghi/logo_sportmix2.png"} width="13%"/></h3><h3 className="shadowtext-1bl">Hip Hop, <br/> ascolto musicale</h3>
                            </div>
                            < div className="four columns align-center p-0" style={{"margin-top":"-20px"}}>
                                <h3><img src={"images/centroestivo/loghi/logo_sportmix2.png"} width="13%"/></h3><h3 className="shadowtext-1bl">English games</h3>
                            </div>
                        </div>

                        <div className="row">
                            <div className="three columns p-0"></div>
                            < div className="six columns align-center p-0" >
                                <h3> <img src={"images/centroestivo/loghi/logo_parcoavventura.png"} width="15%" /></h3><h3 className="shadowtext-1bl">Parco avventura</h3>
                                <h6 className="new-badge align-center">NEW</h6>
                            </div>
                            <div className="three columns p-0"></div>
                        </div>

                        <div className="row">
                            < div className="four columns align-center p-0" style={{"margin-top":"-18px"}}>
                                <h3> <img src={"images/centroestivo/loghi/logo_vulci.png"} width="20%"/> </h3><h3 className="shadowtext-1bl">Escursioni naturalistiche</h3>
                            </div>
                            < div className="four columns align-center p-0" style={{"margin-top":"-48px"}}>
                            <h3> <img src={"images/centroestivo/loghi/logo_masa.png"} width="20%"/> </h3><h3 className="shadowtext-1bl">Riva Trekking</h3>
                            </div>
                        </div>

                        
                    </div>
<br/>
                    

                </Fade>
            </section >
        );
    }
}

export default Attivita;
