export const Attivita_section1 = () => {
    return (
        <div>
            <h3><img src={"images/centroestivo/loghi/logo_sportmix2.png"} width="23%"/></h3><h3 className="shadowtext-1bl">Psicomotricità, <br/>Giochi di movimento, <br/>Giochi di squadra</h3>
            <br />
            <h3> <img src={"images/centroestivo/loghi/logo_masa.png"} width="35%" style={{"margin-top":"-10px"}} /> </h3><h3 className="shadowtext-1bl">Battesimo della canoa</h3>
            <br />
            <h3> <img src={"images/centroestivo/loghi/logo_vulci.png"} width="35%"/> </h3><h3 className="shadowtext-1bl">Escursioni naturalistiche</h3>
            <br />
        </div>
    )
}