import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";
import { ZoomImage } from "../../ZoomImage";

class Eventi extends Component {
  render() {
    if (!this.props.data) return null;

    const next_event_img = "images/" + this.props.data.ordinary_lab.next_event_img;
    const logo_whatsapp = "images/" + this.props.data.loghi.whatsapp;
    const logo_telephone= "images/" + this.props.data.loghi.telephone;
    const whatsapp = "https://api.whatsapp.com/send/?phone=393286236646&text&app_absent=0";

    return (
      <section id="eventi" style={{ "backgroundColor": "#0047AB" }}>
        <div className="row">
          <Slide right duration={1000}>
            <div className="row">
              < div className="align-center">
                <h1>
                  <span style={{ color: "white" }} className="shadowtext-1b"><u>PROSSIMO EVENTO</u></span>
                </h1>
              </div>
            </div>


            <div className="row">
              <Fade bottom>
                <div className="five columns">
                  <div id="iscrizione_div" className="align-center">
                    <ZoomImage className="img_iscrizioni" title="" largeImage={next_event_img} smallImage={next_event_img} />

                  </div>
                </div>
              </Fade>
              <div className="seven columns main-col info-event" style={{ paddingLeft: "30px" }}>
                <div className="row">
                  <div className="columns contact-details" style={{ "marginTop": "25px" }}>
                    <h2 style={{ textShadow: "rgb(93 81 0) 0px 1px 3px", paddingTop: "5px" }}>12 FEBBRAIO 2023</h2>
                    <p className="address" style={{ color: "#fff" }}>
                      <span><b><b className="iconText" style={{ padding: 0 }}>⏱</b> Orario: </b>dalle 14:30 alle 18:30</span><br />

                      <span ><b className="iconText" style={{ padding: 0 }}>📍</b> <b>LUDE Hip Hop Dance school </b> presso <br />
                        <text style={{ "padding-left": "30px" }}> ONE HEALTH AND FITNESS STUDIO <br /></text>
                        <text style={{ "padding-left": "30px", "marginTop":"-10px" }}>Via Punton di Rocca 8, Civitavecchia<br /></text>
                      </span><br />
                      <span ><b> Per Info: </b>Nicoletta + 39 3286236646 <br />
                      <div >
                        <a className="social-links" target="blank" style={{ marginRight: "20px" }} href={whatsapp}>
                          <img alt="whatsapp"  src={logo_whatsapp} style={{ width: "35px" }} />
                        </a><a className="social-links" target="blank" href="tel:3286236646">
                          <img alt="whatsapp" src={logo_telephone} style={{ width: "35px" }} />
                        </a>
                        </div>
                        <br /></span>
                      <br /><br />

                    </p>

                  </div>
                </div>
              </div>
            </div>
          </Slide>
        </div>



      </section>
    );
  }
}

export default Eventi;
