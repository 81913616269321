import React, { Component } from "react";
import Fade from "react-reveal";

class Header extends Component {
  render() {
    if (!this.props.data) return null;

    const description = this.props.data.description;
    const logo = "images/" + this.props.data.cypher_workshop.img_header_cypher_workshop;
    
    return (
      <header id="home_cypher" style={{background: "url(images/cypher_workshop/bkground_cypher.png)", backgroundSize: "cover" }}> {/*#a7cdbd*/}
      
       {/* <ParticlesBg type="circle" bg={true} /> */}

        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home_cypher" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="">
              <a className="" href="/">
                Homepage
              </a>
            </li>
            <li className="current">
              <a className="smoothscroll" href="#home_cypher">
                Home
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#workshop">
                Iscrizione
              </a>
            </li>
            
            <li>
              <a className="smoothscroll" href="#media">
                Media
              </a>
            </li>
            
            <li>
              <a className="smoothscroll" href="#contact">
                Info
              </a>
            </li>
          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text">
            <Fade bottom>
              <img
                id="pic-cypher_lg"
                src={logo}
                alt="Cypher Workshop" 
              />
              <img
                id="pic-cypher_xs"
                src={logo}
                alt="Cypher Workshop"
              />
            </Fade>
            <Fade bottom duration={1200}>
            </Fade>
            
            
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#workshop">
            <i className="icon-down-circle"></i>
          </a>
        </p>
        
      </header>
    );
  }
}

export default Header;
