import React, { Component } from "react";
import Fade from "react-reveal";
import { Image } from "../Image";

class LabChoice extends Component {
    render() {
        if (!this.props.data) return null;

        const path_hip_hop = this.props.data.ordinary_lab.path_hip_hop;
        const path_house = this.props.data.ordinary_lab.path_house;

        const img_hip_hop = "images/" + this.props.data.ordinary_lab.choice_hip_hop;
        const img_house = "images/" + this.props.data.ordinary_lab.choice_house;

        return (
            <section id="homepage" >
                <Fade duration={1000}>
                    <div className="row" style={{"text-align":"center", "color":"white", "padding-bottom":"20px"}}>
                        <h1 style={{"color":"white"}}>Scegli il percorso</h1>
                    </div>
                    <div className="row" >
                        <div className="five columns">
                            <Image title="" path={path_hip_hop} smallImage={img_hip_hop} />
                        </div>
                        <div className="five columns">
                            <Image title="" path={path_house} smallImage={img_house} />
                        </div>
                    </div>

                </Fade>
            </section>
        );
    }
}

export default LabChoice;
