import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";
import $ from 'jquery';
import axios from 'axios';
import emailjs from '@emailjs/browser';


class IscrizioniCoreografico extends Component {

    constructor(props) {
        super(props);
        this.state = ({
            "nome": '',
            "cognome": '',
            "email": '',
            "cellulare":'',
            "categoria": '',
            "nomeGruppo":'',
            "numero_componenti_crew": '',
            "fields": {},
            "errors": {}
        });
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Name
        if (!fields["nome"]) {
            formIsValid = false;
            errors["nome"] = "Inserire il nome";
        }
        //Cognome
        if (!fields["cognome"]) {
            formIsValid = false;
            errors["cognome"] = "Inserire il cognome";
        }
        
        //email
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Inserire indirizzo email";
        } else if(typeof fields["email"] !== '' && !(regex.test(fields["email"]))) {
            formIsValid = false;
            errors["email"] = "Indirizzo email non valido";
            console.log('email non valida');
        } 

        //cellulare
        var regex2 = /^(\+|)*[0-9\s\/\-]*$/;
        if(fields["cellulare"] && (!(regex2.test(fields["cellulare"])) || (fields["cellulare"].length) < 10)) {
            formIsValid = false;
            errors["cellulare"] = "Numero di cellulare non valido";
        }

        //nome gruppo
        if (!fields["nomeGruppo"]) {
            formIsValid = false;
            errors["nomeGruppo"] = "Inserire il nome del gruppo";
        }

        //categoria
        if (!fields["categoria"]) {
            formIsValid = false;
            errors["categoria"] = "Selezionare una categoria";
        }
        //inserire min componenti 
        if (!fields["numero_componenti_crew"]) {
            formIsValid = false;
            errors["numero_componenti_crew"] = "Inserire numero di componenti";
        }
        //numero min componenti 
        if (fields["numero_componenti_crew"] < 2) {
            formIsValid = false;
            errors["numero_componenti_crew"] = "Minimo 2 componenti";
        }
        //numero maxcomponenti 
        if (fields["numero_componenti_crew"] >30) {
            formIsValid = false;
            errors["numero_componenti_crew"] = "Massimo 30 componenti";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = (e.target.value).trim();
        this.setState({ fields });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        $('#submit').prop('disabled', true);;
        if (!this.handleValidation()) {
            console.log('Show Errori');
            $('#submit').prop('disabled', false);
        } else {
            e.preventDefault();
            	
            $('#image-loader').fadeIn();
            axios({
                method: 'post',
                url: 'https://v1.nocodeapi.com/dttm/google_sheets/dKwgSFjBAIoRhrXt/addRows?tabId=Coreo',
                data: [{
                    "Nome Responsabile": this.state.fields.nome,
                    "Cognome Responsabile": this.state.fields.cognome,
                    "Email Responsabile": this.state.fields.email,
                    "Cellulare Responsabile": this.state.fields.cellulare,
                    "Categoria": this.state.fields.categoria,
                    "Nome Gruppo": this.state.fields.nomeGruppo,
                    "Numero Componenti Crew": this.state.fields.numero_componenti_crew,
                    "Data Iscrizione": new Date().toLocaleString()}],
                config: { headers: {'Content-Type': 'application/json'}},
                dataType: "xml"
    
            })
            .then((response)=>{
                if (response.data.message ==="Successfully Inserted"){ 
                    var nomeGruppo = 'Nome Gruppo: ' + this.state.fields.nomeGruppo ;
                    var categoria = ', Categoria: '+ this.state.fields.categoria;
                    var n_componenti = ', Numero Elementi: ' + this.state.fields.numero_componenti_crew;
                    var templateParams = {
                        nome: this.state.fields.nome,
                        cognome: this.state.fields.cognome,
                        cellulare: this.state.fields.cellulare,
                        nomeGruppo: this.state.fields.nomeGruppo,
                        categoria: this.state.fields.categoria,
                        numero_componenti_crew: this.state.fields.numero_componenti_crew,
                        email: this.state.fields.email,
                        evento: 'coreografico',
                        tipo_evento : 'al contest coreografico',
                        data_evento: '25/04/2022',
                        message: (nomeGruppo+categoria+n_componenti),
                        destinatario: this.state.fields.email
                    };
                    emailjs.send('service_v4a8r9t', 'template_1eur3d7', templateParams, 'fi97WTtUM-HCf0egE')
                            .then(function(response) {
                               console.log('SUCCESS!', response.status, response.text);
                            }, function(error) {
                               console.log('FAILED...', error);
                            });
                    
                    $("#image-loader").fadeOut();
                    $("#message-warning").hide();
                    $("#form_iscrizione").css("display", "none");
                    $("#message-success").fadeIn();
                    $('#form_iscrizione')[0].reset();
                } else {
                    $("#image-loader").fadeOut();
                    $("#message-warning").fadeIn();
                }
            })
            .catch((err)=>{
                console.log(err);
                $("#image-loader").fadeOut();
                $("#message-warning").fadeIn();
            });
        }
    }
    render() {
        if (!this.props.data) return null;

        const img_header = "images/dttm/header_iscrizioni_coreo.png";//+ this.props.data.coreo.img_header_iscrizioni;
        const img_header_mobile = "images/dttm/" + this.props.data.coreo.img_header_iscrizioni_mobile;
        const name_nome = this.props.data.coreo.name_form.nomeResp;
        const name_cognome = this.props.data.coreo.name_form.cognomeResp;
        const name_email = this.props.data.coreo.name_form.emailResp;
        const name_cellulare = this.props.data.coreo.name_form.cellulareResp;
        const name_categoria = this.props.data.coreo.name_form.categoria;
        const name_nome_gruppo = this.props.data.coreo.name_form.nomeGruppo;
        const name_numero_componenti_crew = this.props.data.coreo.name_form.numeroComponenti;

     

        return (
            <section id="contact">
                <Fade bottom duration={1000}>
                    <div className="row">
                        <img alt="header" id="img_header_lg" src={img_header} />
                        <img alt="header" id="img_header_xs" src={img_header_mobile} />
                    </div>
                </Fade>
                <div id="message-warning" className="text-center"> Attenzione! Si è verificato un errore imprevisto, riprovare!</div>
                <div id="message-success" className="text-center">
                    <i className="fa fa-check"></i>La tua richiesta di iscrizione è stata inviata correttamente. Verrai contattato all'indirizzo email comunicato per la conferma.
                    <br/>
                    <a style={{ paddingLeft: "00%" }} href="/dancetothemusic"><button className="btnFileCoreo" style={{ marginTop:"50px", marginRight:"10px" }}>Homepage</button></a>
                    <a style={{ paddingLeft: "00%" }} href="/dancetothemusic_iscrizioni_coreo"><button className="btnFileCoreo" style={{  marginTop:"50px" }}>Nuova richiesta di iscrizione</button></a>
                    <br />
                </div>

                <div className="row" id="div_main">
                    <Slide left duration={1000}>
                    
                        <form id="form_iscrizione" >
                        <div className="row">
                            < div className="align-center">
                                <h1>
                                    <span style={{ color: "#white", textShadow: "2px 2px 2px #000", fontFamily: 'oakland-raiders', paddingBottom:"0px" }}>Richiesta di partecipazione alla gara coreografica</span>
                                </h1>
                            </div>
                        </div>
                            <fieldset>
                                <div >
                                <hr style={{border:"solid 1px #990014"}}/>
                                    <div className="twelve columns text-left">
                                        <h3 className="subtitle_form">Dati Responsabile</h3>
                                    </div>

                                    <div className="six columns divColumn">
                                        <label htmlFor="nome">
                                            Nome <span className="required">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            defaultValue=""
                                            size="35"
                                            id="nome"
                                            name={name_nome}
                                            required
                                            onChange={this.handleChange.bind(this, "nome")}
                                        />
                                        <span style={{ color: "red", "fontSize": "12px" }}>{this.state.errors["nome"]}</span>
                                    
                                    </div>

                                    <div className="six columns divColumn">
                                        <label htmlFor="cognome">
                                            Cognome <span className="required">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            defaultValue=""
                                            size="35"
                                            id="cognome"
                                            name={name_cognome}
                                            required
                                            onChange={this.handleChange.bind(this, "cognome")}
                                        />
                                        <span style={{ color: "red", "fontSize": "12px" }}>{this.state.errors["cognome"]}</span>
                                    </div>

                                    <div className="six columns divColumn">
                                        <label htmlFor="email">
                                            Email <span className="required">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            defaultValue=""
                                            size="150"
                                            id="email"
                                            name={name_email}
                                            required
                                            onChange={this.handleChange.bind(this, "email")}
                                        />
                                        <span style={{ color: "red", "fontSize": "12px" }}>{this.state.errors["email"]}</span>
                                    </div>

                                    <div className="six columns divColumn">
                                        <label htmlFor="cellulare">
                                            Cellulare
                                        </label>
                                        <input
                                            type="tel"
                                            defaultValue=""
                                            size="35"
                                            id="cellulare"
                                            name={name_cellulare}
                                            onChange={this.handleChange.bind(this, "cellulare")}
                                        />
                                        <span style={{ color: "red", "fontSize": "12px" }}>{this.state.errors["cellulare"]}</span>
                                    </div>
                                    <hr className="hr_form_separator"/>
                        
                                    <div className="six columns divColumn">
                                        <label htmlFor="nome_gruppo">
                                            Nome Gruppo <span className="required">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            defaultValue=""
                                            size="150"
                                            id="nomeGruppo"
                                            name={name_nome_gruppo}
                                            required
                                            onChange={this.handleChange.bind(this, "nomeGruppo")}
                                        />
                                        <span style={{ color: "red", "fontSize": "12px" }}>{this.state.errors["nomeGruppo"]}</span>
                                    </div>

                                    <div className="six columns divColumn">
                                        <label htmlFor="categoria">
                                            Categoria <span className="required">*</span>
                                        </label>
                                        <select name={name_categoria} className="styleInput" id="categoria" required onChange={this.handleChange.bind(this, "categoria")}>
                                            <option value="undefined">Seleziona categoria..</option>
                                            <option value="Cuccioli" >Cuccioli (Under 12)</option>
                                            <option value="Pischelli">Pischelli (Under 16)</option>
                                            <option value="Seri">Seri (Over 16)</option>
                                        </select>
                                        <span style={{ color: "red", "fontSize": "12px" }}>{this.state.errors["categoria"]}</span>
                                    </div>

                                    <div className="six columns divColumn" id="div_n_elementi" >
                                            <label htmlFor="numero_componenti_crew">
                                                N° Elementi <span className="required">*</span>
                                                <span style={{ "fontSize": "13px" }}>(Min 2)</span>
                                            </label>
                                            <input
                                                type="number"
                                                id="numero_componenti_crew"
                                                name={name_numero_componenti_crew}
                                                onChange={this.handleChange.bind(this, "numero_componenti_crew")}
                                            />
                                            <span style={{ color: "red", "fontSize": "12px" }}>{this.state.errors["numero_componenti_crew"]}</span>
                                        </div>
                                    <hr style={{border:"solid 1px #990014", "marginTop": "50px"}}/>

                                    <div className="twelve columns align-center">
                                    <button style={{ background:"#990514"}} type="submit" className="submit" id="submit" onClick={this.handleSubmit}>Invia</button>
                                        <span id="image-loader">
                                            <img alt="" src="images/loader.gif" />
                                        </span>
                                    </div>

                                </div>

                            </fieldset>
                        </form>
                    </Slide>
                </div>

            </section>
        );
    }
}

export default IscrizioniCoreografico;
