export const Attivita_section3 = () => {
    return (
        <div>
            <h3><img src={"images/centroestivo/loghi/logo_sportmix2.png"} width="23%"/></h3><h3 className="shadowtext-1bl">Hip Hop, ascolto musicale</h3>
            <br />
            <h3> <img src={"images/centroestivo/loghi/logo_parcoavventura.png"} width="23%" style={{marginTop:"-10px"}}/> </h3><h3 className="shadowtext-1bl">Parco Avventura</h3>
            <h6 className="new-badge align-center">NEW</h6>
            <br />
            <h3> <img src={"images/centroestivo/loghi/logo_bike.png"} width="20%" /> </h3><h3 className="shadowtext-1bl">Mountain Bike</h3>
            <h6 className="new-badge align-center">NEW</h6>
        </div>
    )
}