export const Programma_section2 = () => {
    return (
        <div>
            <h2 className="shadowtext-1b" style={{color:"rgb(0 217 255)", paddingBottom:"10px"}}>2° SETTIMANA</h2>
            <h3>―――――<b className="iconActivity3">Lun</b>―――――</h3>
            <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Piani degli Alpaca <br/>
            <text className="shadowtext-1b">Pranzo al sacco </text><br/>
            <text className="shadowtext-1b">Pomeriggio:</text> Piani degli Alpaca</h3>
            <p>Consegna e ritiro bambini ai Piani degli Alpaca</p>
        
            <h3>―――――<b className="iconActivity3">Mar</b>―――――</h3>
            <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text>Pineta<br/>
            <text className="shadowtext-1b">Pranzo: </text> Garden Club<br/>
            <text className="shadowtext-1b">Pomeriggio:</text> Cambusiero</h3>
            <p>Consegna e ritiro bambini al Garden Club</p>
        
            <h3>―――――<b className="iconActivity3">Mer</b>―――――</h3>
            <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Palestra Sport Mix Due <br/>
            <text className="shadowtext-1b">Pranzo al sacco </text><br/>
            <text className="shadowtext-1b">Pomeriggio:</text> Campo Martelli</h3>
            <p>Consegna e ritiro bambini al Palazzetto dello Sport</p>
        
            <h3>―――――<b className="iconActivity3">Gio</b>―――――</h3>
            <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Pineta; English Game<br/>
            <text className="shadowtext-1b">Pranzo al sacco </text><br/>
            <text className="shadowtext-1b">Pomeriggio:</text> Cambusiero</h3>
            <p>Consegna e ritiro bambini al Garden Club</p>
        
            <h3>―――――<b className="iconActivity3">Ven</b>―――――</h3>
            <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Parco Archeologico di Vulci<br/>
            <text className="shadowtext-1b">Pranzo: </text> Casaletto Mengarelli<br/>
            <text className="shadowtext-1b">Pomeriggio:</text> Laghetto di Vulci</h3>
            <p>Consegna e ritiro bambini al Parco Archeologico di Vulci<br/></p>
            <br />
        </div>
    )
}