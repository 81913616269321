import React, { Component } from "react";
import Fade from "react-reveal";

class Header extends Component {
  render() {
    if (!this.props.data) return null;

    const description = this.props.data.description;
    const logo = "images/centroestivo/" + this.props.data.logo_centro;
    return (
     <header id="welcome"  >
      
      
       {/* <ParticlesBg type="circle" bg={true} /> */}

        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#welcome" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="">
              <a className="" href="/">
                Homepage
              </a>
            </li>

            <li className="current">
              <a className="smoothscroll" href="#welcome">
                Welcome
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#date_ce">
                Date
              </a>
            </li>
            
            <li>
              <a className="smoothscroll" href="#attivita">
                Attività
              </a>
            </li>

        {/*    <li>
              <a className="smoothscroll" href="#programma">
                Programma
              </a>
    </li>*/}

            <li>
              <a className="smoothscroll" href="#info">
                Info e Programma
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#contatti">
                Contatti
              </a>
            </li>
          </ul>

          
        </nav>

        <div className="row banner">
          <div className="banner-text">
            <Fade bottom>
              <img
                className="profile-pic"
                src={logo}
                style={{height:"400px"}}
                alt="Centro estivo logo"
              />
            </Fade>
            <Fade bottom duration={1200}>
            </Fade>
            
            
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#date_ce">
            <i className="icon-down-circle"></i>
          </a>
        </p>
        
      </header>
    );
  }
}

export default Header;
