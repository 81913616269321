import React, { Component } from "react";
import Fade from "react-reveal";

class Header extends Component {
  render() {
    if (!this.props.data) return null;

    const description = this.props.data.description;
    const logo = "images/" + this.props.data.ordinary_lab.logo_lab_house;
    

    return (
      <header id="welcome_lab_hh"  >
        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="">
              <a className="" href="/">
                Home
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#about">
                About
              </a>
            </li>
            
            <li>
              <a className="smoothscroll" href="#resume">
                Chi siamo?
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#info">
                Prenota
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#media">
                Media
              </a>
            </li>
            

            <li>
              <a className="" href="/ordinary_lab_hip_hop">
                Percorso Hip Hop
              </a>
            </li>

            

          </ul>
        </nav>

<div className="row banner">
<div className="banner-text">
  <Fade bottom>
    <img
      className="profile-pic"
      src={logo}
      style={{height:"400px"}}
      alt="Ordinary Lab Logo"
    />
  </Fade>
  <Fade bottom duration={1200}>
    <h3>{description}</h3>
  </Fade>
  
  
</div>
</div>

<p className="scrolldown">
<a className="smoothscroll" href="#about">
  <i className="icon-down-circle"></i>
</a>
</p>

</header>
    );
  }
}

export default Header;
