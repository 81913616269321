export const Attivita_section2 = () => {
    return (
        <div>
            <h3> <img src={"images/centroestivo/loghi/logo_molozero.jpg"} width="15%"/> </h3><h3 className="shadowtext-1bl">Capitano per un giorno</h3>
            <h6 className="new-badge align-center">NEW</h6>
            <br />
            <h3> <img src={"images/centroestivo/loghi/logo_cambusiero.png"} width="25%"/> </h3><h3 className="shadowtext-1bl">Mare, <br/> giochi in spiaggia, <br/> attività in pineta</h3>
            <br />
            <h3> <img src={"images/centroestivo/loghi/logo_ortostorto.png"} width="30%" style={{"padding-right":"10px"}}/><img src={"images/centroestivo/loghi/logo_alicenova.png"} width="25%"/> </h3><h3 className="shadowtext-1bl">Attività Didattiche</h3>
        </div>
    )
}