import React, { Component } from "react";
import Fade from "react-reveal";
import { ZoomImage } from "../../ZoomImage";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const profilepic = "images/" + this.props.data.image;
    const bio = this.props.data.bio;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const email = this.props.data.email;
    const resumeDownload = this.props.data.resumedownload;

    const general_desc = this.props.data.ordinary_lab.general_desc;
    const house_desc = this.props.data.ordinary_lab.house_desc;
    const house_photo = "images/" + this.props.data.ordinary_lab.choice_house;

    return (
      <section id="about">
        <Fade duration={1000}>
        <div className="row align-center">
            < div className="align-center">
                <h1>
                    <span style={{ color: "white" }} className="shadowtext-1b"><u>ABOUT</u></span>
                </h1>
            </div>
        </div>

        <div className="row">
            <div className="nine columns main-col">
                <h2>Cosa è Ordinary LAB?</h2>
                <p id="general_desc">Ordinary Lab è un laboratorio ideato da <b>Marco Cavalloro</b> e <b>dj Collo</b>. L'unione di esercizi mirati, ascolto musicale e nozioni culturali sono il <b>punto di forza</b> di questa proposta che vuole guidare i partecipanti a fondere maggiormente musica e danza nel proprio movimento. <b>Marco</b> si occuperà della danza proponendo metodi di allenamento esercizi tecnici e nozioni sulla didattica. <b>Collo</b> invece della parte musicale attraverso ascolto e analisi di una traccia sia nell'aspetto tecnico che culturale.</p>
            </div>
            <div className="three columns main-col"></div>
        </div>

        <div className="row">
        <div className="three columns main-col">
            <ZoomImage className="img_iscrizioni" title="" largeImage={house_photo} smallImage={house_photo} />
        </div>
            
            <div className="nine columns main-col">
                <h2>Percorso House</h2>
                <p>{house_desc}</p>
            </div>
        </div>
        </Fade>
      </section>
    );
  }
}

export default About;
