import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";
import axios from 'axios';
import $ from 'jquery';
import emailjs from '@emailjs/browser';

class Iscrizioni_stage extends Component {
    
    constructor(props) {
        super(props);
        this.fineDef = React.createRef();
        this.state = ({
            "nome": '',
            "cognome": '',
            "data_nascita": null,
            "email": '',
            "cellulare": '',
            "hiphop": '',
            "house":'',
            "popping":'',
            "lezioni_selezionate": '',
            "importo_stage": '',
            "fields": {},
            "errors": {}
        });
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Name
        if (!fields["nome"]) {
            formIsValid = false;
            errors["nome"] = "Inserire il nome";
        }
        //Cognome
        if (!fields["cognome"]) {
            formIsValid = false;
            errors["cognome"] = "Inserire il cognome";
        }
        //data di nascita
        if (!fields["dataNascita"]) {
            formIsValid = false;
            errors["dataNascita"] = "Inserire la data di nascita";
        }
        if (fields["dataNascita"] < '1900-01-01') {
            formIsValid = false;
            errors["dataNascita"] = "Data di nascita non valida";
        }

        if (fields["dataNascita"] >= '2017-01-01') {
            formIsValid = false;
            errors["dataNascita"] = "Data di nascita non valida";
        }
       
        //email
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Inserire indirizzo email";
        } else if (typeof fields["email"] != '' && !(regex.test(fields["email"]))) {
            formIsValid = false;
            errors["email"] = "Indirizzo email non valido";
            console.log('email non valida');
        }

        //cellulare
        var regex2 = /^(\+|)*[0-9\s\/\-]*$/;
        if (fields["cellulare"] && (!(regex2.test(fields["cellulare"])) || (fields["cellulare"].length) < 10)) {
            formIsValid = false;
            errors["cellulare"] = "Numero di cellulare non valido";
        }

        //lezioni
        if (!fields["hiphop"] && !fields["house"] && !fields["popping"]) {
            formIsValid = false;
            errors["lezioni"] = "Selezionare almeno una lezione";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        if (field === "hiphop" || field === "house" || field === "popping") {
            fields[field] = (e.target.checked);
        } else {
            fields[field] = (e.target.value).trim();
        }
        this.setState({ fields });
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        $('#submit').prop('disabled', true);;
        if (!this.handleValidation()) {
            console.log('Show Errori');
            $('#submit').prop('disabled', false);
        } else {
            e.preventDefault();
            let lezioni_selezionate_arr= [];
            if (this.state.fields.hiphop) lezioni_selezionate_arr.push("Hip Hop");
            if (this.state.fields.house) lezioni_selezionate_arr.push("House");
            if (this.state.fields.popping) lezioni_selezionate_arr.push("Popping");
            this.lezioni_selezionate = lezioni_selezionate_arr.join(', ');
            console.log(this.lezioni_selezionate); 
            this.importo_stage = lezioni_selezionate_arr.length === 3 ? '40' : lezioni_selezionate_arr.length === 1? '15' : '30';
            console.log(this.importo_stage);
            $('#image-loader').fadeIn();
            axios({
                method: 'post',
                url: 'https://v1.nocodeapi.com/dttm/google_sheets/dKwgSFjBAIoRhrXt/addRows?tabId=Stage',
                data: [{
                    "Nome": this.state.fields.nome,
                    "Cognome": this.state.fields.cognome,
                    "Data Nascita": this.state.fields.dataNascita,
                    "Email": this.state.fields.email,
                    "Cellulare": this.state.fields.cellulare,
                    "Popping": this.state.fields.popping ? 'SI': '',
                    "House": this.state.fields.house? 'SI': '',
                    "Hip Hop": this.state.fields.hiphop? 'SI': '',
                    "Data Iscrizione": new Date().toLocaleString()
                }],
                config: { headers: { 'Content-Type': 'application/json' } },
                dataType: "xml"

            })
                .then((response) => {
                    if (response.data.message === "Successfully Inserted") {
                         
                        var templateParams = {
                            nome: this.state.fields.nome,
                            email: this.state.fields.email,
                            importo_stage: this.importo_stage,
                            lezioni_selezionate: this.lezioni_selezionate
                        };
                         
                       emailjs.send('service_v4a8r9t', 'template_tzk014m', templateParams, 'fi97WTtUM-HCf0egE')
                            .then(function(response) {
                               console.log('SUCCESS!', response.status, response.text);
                            }, function(error) {
                               console.log('FAILED...', error);
                            });
                        $("#image-loader").fadeOut();
                        $("#message-warning").hide();
                        $("#form_iscrizione").css("display", "none");
                        $("#message-success").fadeIn();
                        $('#form_iscrizione')[0].reset();
                    } else {
                        $("#image-loader").fadeOut();
                        $("#message-warning").fadeIn();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    $("#image-loader").fadeOut();
                    $("#message-warning").fadeIn();
                });
        }
    }

    render() {
        if (!this.props.data) return null;

        const img_header = "images/dttm/header_iscrizioni_coreo.png";//+ this.props.data.coreo.img_header_iscrizioni;
        const img_header_mobile = "images/dttm/" + this.props.data.coreo.img_header_iscrizioni_mobile;
        
        const name_nome = this.props.data.stage.name_form.nome;
        const name_cognome = this.props.data.stage.name_form.cognome;
        const name_data_nascita = this.props.data.stage.name_form.dataNascita;
        const name_email = this.props.data.stage.name_form.email;
        const name_cellulare = this.props.data.stage.name_form.cellulare;


        return (
            <section id="contact">
                <Fade bottom duration={1000}>
                    <div className="row">
                        <img id="img_header_lg" src={img_header} />
                        <img id="img_header_xs" src={img_header_mobile} />
                    </div>
                </Fade>
                <div alt="footer" id="message-warning" className="text-center"> Attenzione! Si è verificato un errore imprevisto, riprovare!</div>
                <div alt="footer" id="message-success" className="text-center">
                    <i className="fa fa-check"></i>La tua iscrizione è stata inserita correttamente!
                    <br/>
                    <a style={{ paddingLeft: "00%" }} href="/dancetothemusic"><button style={{ backgroundColor: "#ee9000", marginTop:"50px", marginRight:"10px" }}>Home</button></a>
                    <a style={{ paddingLeft: "00%" }} href="/dancetothemusic_iscrizioni_stage"><button style={{ backgroundColor: "#115394", marginTop:"50px" }}>Nuova iscrizione workshop</button></a>
                    <br />
                </div>
                <div className="row" id="div_main">
                    <Slide left duration={1000}>
                        <form id="form_iscrizione">
                            <fieldset>
                                <div >
                                <hr className="hr_form_separator"/>
                                    <div className="six columns divColumn">
                                        <label htmlFor="nome">
                                            Nome <span className="required">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            defaultValue=""
                                            size="35"
                                            id="nome"
                                            name={name_nome}
                                            required
                                            onChange={this.handleChange.bind(this, "nome")}
                                        />
                                        <span style={{ color: "red", "fontSize": "12px" }}>{this.state.errors["nome"]}</span>
                                    </div>

                                    <div className="six columns divColumn">
                                        <label htmlFor="cognome">
                                            Cognome <span className="required">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            defaultValue=""
                                            size="35"
                                            id="cognome"
                                            name={name_cognome}
                                            required
                                            onChange={this.handleChange.bind(this, "cognome")}
                                        />
                                        <span style={{ color: "red", "fontSize": "12px" }}>{this.state.errors["cognome"]}</span>
                                    </div>

                                    <div className="six columns divColumn">
                                        <label htmlFor="dataNascita">
                                            Data nascita <span className="required">*</span>
                                        </label>
                                        <input
                                            style={{ display: "block" }}
                                            type="date"
                                            min="1918-01-01" max="2022-01-31"
                                            id="dataNascita"
                                            name={name_data_nascita}
                                            required
                                            onChange={this.handleChange.bind(this, "dataNascita")}
                                            requiredpattern="\d{4}-\d{2}-\d{2}"
                                        />
                                        <span style={{ color: "red", "fontSize": "12px" }}>{this.state.errors["dataNascita"]}</span>
                                    </div>

                                    <div className="six columns divColumn">
                                        <label htmlFor="email">
                                            Email <span className="required">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            defaultValue=""
                                            size="150"
                                            id="email"
                                            name={name_email}
                                            required
                                            onChange={this.handleChange.bind(this, "email")}
                                        />
                                        <span style={{ color: "red", "fontSize": "12px" }}>{this.state.errors["email"]}</span>
                                    </div>

                                    <div className="six columns divColumn">
                                        <label htmlFor="cellulare">
                                            Cellulare
                                        </label>
                                        <input
                                            type="tel"
                                            defaultValue=""
                                            size="35"
                                            id="cellulare"
                                            name={name_cellulare}
                                            onChange={this.handleChange.bind(this, "cellulare")}
                                        />
                                        <span style={{ color: "red", "fontSize": "12px" }}>{this.state.errors["cellulare"]}</span>
                                        
                                    </div>
                                    <div className="ten columns divColumn">
                                        <label  className="labelCheckTitle"  htmlFor="lezioni" >Lezioni</label>
                                        <br/>
                                        <label className="labelCheck" style={{"width":"30%", "float": "none"}}>
                                            <input type="checkbox" id="hiphop" value="hiphop" name="hipHop" style={{"width":"23px"}} disabled onChange={this.handleChange.bind(this, "hiphop")} />
                                            HipHop (sold out)
                                        </label>
                                        <label className="labelCheck" style={{"width":"20%", "float": "none"}}>
                                            <input type="checkbox" value="house" id="house" name="house" style={{width:"23px"}} onChange={this.handleChange.bind(this, "house")} />
                                            House
                                        </label>
                                        <label className="labelCheck" style={{"width":"20%", "float": "none"}}>
                                            <input type="checkbox" value="popping" id="popping" name="popping" style={{width:"23px"}} onChange={this.handleChange.bind(this, "popping")} />
                                            Popping
                                        </label>
                                        <span style={{ color: "red", "fontSize": "12px", "marginBottom":"20px" }}>{this.state.errors["lezioni"]}</span>
                                        </div>
                                        

                                    <hr className="hr_form_separator"/>
                                    <div className="twelve columns">
                                    <button type="submit" className="submit" id="submit" onClick={this.handleSubmit}>Invia</button>
                                        <span id="image-loader">
                                            <img alt="" src="images/loader.gif" />
                                        </span>
                                    </div>


                                </div>


                            </fieldset>
                        </form>
                    </Slide>
                </div>

            </section>
        );
    }
}

export default Iscrizioni_stage;
