import React, { Component } from "react";
import Fade from "react-reveal";
import { Image } from "./Image";

class Home extends Component {

    handleClick = (url) => {
        window.location.href = url;
      };

    render() {
        if (!this.props.data) return null;

        const path_dance = this.props.data.path_dance;
        const path_centroestivo = this.props.data.path_centroestivo;
        const path_ordinarylab = this.props.data.ordinary_lab.path_ordinarylab;
        const path_cypherworkshop = this.props.data.cypher_workshop.path_cypher_workshop;
        const path_moka = this.props.data.path_moka;
        const path_vimeo = this.props.data.path_vimeo;

        const img_dance = "images/" + this.props.data.home_dance;
        const img_moka = "images/" + this.props.data.home_moka;
        const img_centroestivo = "images/" + this.props.data.home_centroestivo;
        const img_ordinarylab = "images/" + this.props.data.ordinary_lab.home_ordinary_lab;
        const img_cyperworkshop = "images/" + this.props.data.cypher_workshop.img_header_cypher_workshop;
        const img_vimeo = "images/" + this.props.data.home_vimeo;

        return (
            <section id="homepage" >
                <div id="homepage_web">
                    <Fade duration={1000}>
                        <div className="row">
                            <div className="four columns">
                                    <Image title="" target="_blank" path={path_moka} smallImage={img_moka} />
                            </div>
                            <div className="four columns">
                                <Image title="" target="_blank" path={path_vimeo} smallImage={img_vimeo} />
                            </div>
                            <div className="four columns">
                                <Image title="" path={path_dance} smallImage={img_dance} />
                            </div>    
                        </div>
                        <div className="row">
                            <div className="four columns">
                                <Image title="" path={path_ordinarylab} smallImage={img_ordinarylab} />
                            </div>
                            <div className="four columns">
                                <Image title="" path={path_cypherworkshop} smallImage={img_cyperworkshop} />
                            </div>
                            <div className="four columns">
                                <Image title="" path={path_centroestivo} smallImage={img_centroestivo} />
                            </div>
                        </div>
                    </Fade>
                </div>

                <div id="homepage_mobile" className="align-center">
                    <Fade duration={1000}>
                        <div className="row align-center" style={{padding: "0px"}}>
                            <div className="twelve columns ">
                                <a href={path_moka}> <img src={img_moka} alt="Moka" style={{ cursor: 'pointer' , maxWidth: "45%"}} /></a>
                                <a href={path_vimeo}> <img src={img_vimeo} alt="Vimeo" style={{ cursor: 'pointer' , maxWidth: "45%"}}/> </a>
                            </div>
                        </div>
                        <div className="row align-center" style={{padding: "0px"}}>
                            <div className="twelve columns ">
                                <a href={path_dance}> <img src={img_dance} alt="Dance to the music" style={{ cursor: 'pointer' , maxWidth: "45%"}} /></a>
                                <a href={path_ordinarylab}> <img src={img_ordinarylab} alt="Ordinary Lab" style={{ cursor: 'pointer' , maxWidth: "45%"}}/> </a>
                            </div>
                        </div>
                        <div className="row align-center" style={{padding: "0px"}}>
                            <div className="twelve columns ">
                                <a href={path_cypherworkshop}> <img src={img_cyperworkshop} alt="Cypher Workshop" style={{ cursor: 'pointer' , maxWidth: "45%"}} /></a>
                                <a href={path_centroestivo}> <img src={img_centroestivo} alt="Centro Estivo" style={{ cursor: 'pointer' , maxWidth: "45%"}}/> </a>
                            </div>
                        </div>

                        
                    </Fade>
                </div>
            </section>
        );
    }
}

export default Home;
