import React, { Component } from "react";
import Fade from "react-reveal";
import { ZoomImage } from "../ZoomImage";

class Battle extends Component {
    state = { isOpen: false };

    handleShowDialog = () => {
        this.setState({ isOpen: !this.state.isOpen });
        console.log("cliked");
    };

    render() {
        if (!this.props.data) return null;

        const img_battle = "images/" + this.props.data.battle.img_riquadro_iscrizioni;
        const photo_giuria_hiphop = "images/" + this.props.data.dttm.photo_giuria_hiphop;
        const photo_giuria = "images/" + this.props.data.dttm.photo_giuria;
        const photo_dj_collo = "images/" + this.props.data.dttm.photo_dj_collo;
        const photo_yoshi = "images/" + this.props.data.dttm.photo_yoshi;
        const photo_djbeatmaker = "images/" + this.props.data.dttm.photo_djbeatmaker;


        return (
            <section id="battle" >
                <Fade duration={1000}>
                    <div className="row">
                        < div className="align-center">
                            <h1>
                                <span style={{ color: "#fff", textShadow: "2px 2px 2px #000", fontFamily: 'oakland-raiders' }}>Battle To The Music</span>
                            </h1>
                        </div>
                        <br />
                    </div>

                    <div id="battle_web">
                        <div className="row">
                            <div className="three columns"></div>
                            <div className="six columns">
                                <div className="card card-3d" style={{ "border-radius": "8px", "padding": "5px", "background": "url(../images/dttm/card_background.png)" }}>
                                    <div className="row">
                                        <div className="twelve columns" style={{ "text-align": "center" }}>
                                            <h2 className="textShadowBlackLight">Giuria</h2>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="twelve columns" style={{ "text-align": "center" }}>
                                            <img src={photo_giuria} className="photoGuest" alt="Giuria DTTM" style={{ "max-width": "80%" }} />
                                            <h2 style={{ "text-align": "center", "font-size": "22px" }}>Cresh - Still - Glo' - Frenkyz</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="three columns"></div>
                        </div>

                        <div className="row" style={{ "margin-top": "20px" }}>
                            {/* info */}
                            <div className="two column"></div>
                            <div className="four columns">
                                <div className="card card-3d guestCard" style={{ "border-radius": "8px", "padding": "60px 10px 60px 10px", "background": "url(../images/dttm/card_background.png)" }}>
                                    <div className="align-center" >
                                        <h2 className="dttmFontGrey textShadowBlackLight"> 🗓️ 26 Aprile 2024</h2>
                                        <h2 className="dttmFontGrey textShadowBlackLight"> ⏰ Inizio Check-In: <br/>Ore 15.00</h2>
                                        <h3 className="dttmFontGrey textShadowBlackLight"> ⏰ Inizio Categorie Under: <br/>Ore 16.00</h3>
                                        <h3 className="dttmFontGrey textShadowBlackLight"> ⏰ Inizio Categorie Over: <br/>Ore 17.30</h3>
                                       
                                        {/*<h5 className="textShadowBlackLight" style={{"color":"white", marginTop: "10px"}}>🗂️  ISCRIZIONE BATTLE GRATUITA</h5>
                                    <h6 className="textShadowBlackLight" style={{"color":"white", marginTop: "10px"}}>🎟️  <u>Ingresso per pubblico e ballerini:</u> 15€</h6>
        <a style={{ paddingLeft: "0%" }} href="/dancetothemusic_iscrizioni_battle"><button className="btnFileCoreo" style={{ marginTop: "20px" }}>Iscrizione</button></a><br /> */}
                                    </div>
                                </div>
                            </div>
                            {/* categorie */}
                            <div className="four columns categorie">
                                <div className="card card-3d guestCard" style={{ "border-radius": "8px", "padding": "10px", "background": "url(../images/dttm/card_background.png)" }}>
                                    <div className="align-center" >
                                        <h2 style={{ color: "#fff", fontFamily: 'oakland-raiders' }} className="textShadowBlack">Categorie</h2>
                                        <h3 className="textShadowBlackLight"> 1vs1 Mixstyle (Under 12) <br /> <text style={{ "font-size": "15px" }}>su musica Hip Hop</text></h3>
                                        <h3 className="textShadowBlackLight"> 1vs1 Mixstyle (Under 16) <br /> <text style={{ "font-size": "15px" }}>su musica Hip Hop</text></h3>
                                        <h3 className="textShadowBlackLight"> 1vs1 HipHop </h3>
                                        <h3 className="textShadowBlackLight"> 1vs1 House</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="two column"></div>
                        </div>

                        {/*
                    <div className="row">
                        <div className="two columns"></div>
                        <div className="four columns" style={{"margin-top":"20px"}}>
                            <div className="card card-3d" style={{"border-radius":"8px", "padding":"5px", "background": "url(../images/dttm/card_background.png)"}}>
                                <div className="row">
                                    <div className="twelve columns">
                                        <h2 className="guestH2">Giuria Battle Hip Hop</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="twelve columns" style={{"text-align":"center"}}>
                                        <img src={photo_giuria_hiphop} className="photoGuest" alt="Giuria DTTM" style={{ "max-width": "64%", "padding-top":"20px" }} /> 
                                    <h2 style={{"text-align":"center", "font-size": "22px"}}>Still - Cresh - Frenkyz</h2>
                                    <h2 style={{ color: "#fff", fontFamily: 'oakland-raiders' }} className="textShadowBlack">Categoria 1vs1</h2>
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div className="four columns" style={{"margin-top":"20px"}}>
                            <div className="card card-3d" style={{"border-radius":"8px", "padding":"5px", "background": "url(../images/dttm/card_background.png)"}}>
                                <div className="row">
                                    <div className="twelve columns">
                                        <h2 className="guestH2">Giuria Battle House</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="twelve columns" style={{"text-align":"center"}}>
                                        <img src={photo_giuria_house} className="photoGuest" alt="Giuria DTTM" style={{ "max-width": "75%", "padding-top":"20px" }} /> 
                                    <h2 style={{"text-align":"center", "font-size": "22px"}}>Cresh - Glo' - Still </h2>
                                    <h2 style={{ color: "#fff", fontFamily: 'oakland-raiders' }} className="textShadowBlack">Categoria 1vs1</h2>
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div className="two columns"></div>
        </div>*/}
                        <div className="row" style={{ "margin-top": "20px" }}>
                            <div className="two column"></div>
                            <div className="eight columns">
                                <div className="card card-3d card-iscrizione" style={{ "border-radius": "8px", "padding": "10px", "background": "#3c030b7d" }}>
                                    <div className="align-center" >
                                        
                                        <h5 className="textShadowBlackLight" style={{ "color": "white" }}>Le iscrizioni <u>ONLINE</u> sono chiuse, <br/>sarà comunque possibile iscriversi sul posto <br/>a partire dalle ore 15.00.</h5>
                                        
                                        
                                        <br/>
                                        <h6 className="textShadowBlackLight" style={{ "color": "white" }}>💵 ISCRIZIONE BATTLE GRATUITA</h6>
                                        {/*<h6 className="textShadowBlackLight" style={{"color":"white", marginTop: "10px"}}>📝  Per richiedere la <u>partecipazione</u> inviare una mail a: <a target="_blank" style={{color:"#b2b2b2"}} href="mailto: dancetothemusicmontalto@gmail.com">dancetothemusicmontalto@gmail.com</a></h6>
                                    <h6 className="textShadowBlackLight" style={{"color":"white", marginTop: "10px"}} >🗂️  Per il <u>regolamento</u> inviare una mail a: <a target="_blank" style={{color:"#b2b2b2"}} href="mailto: dancetothemusicmontalto@gmail.com">dancetothemusicmontalto@gmail.com</a> </h6>*/}
                                        <h6 className="textShadowBlackLight" style={{ "color": "white", marginTop: "10px" }}>🎟️  <u>Ingresso per pubblico e ballerini:</u> 15€</h6>
                                       {/* <a style={{ paddingLeft: "0%" }} href="/dancetothemusic_iscrizioni_battle"><button className="btnFileCoreo" style={{ marginTop: "20px" }}>Iscrizione</button></a><br />*/}
                                    </div>
                                </div>
                            </div>
                            <div className="two column"></div>
                        </div>


                        <div className="row" >
                            <div className="two columns"></div>
                            <div className="eight columns" style={{ "margin-top": "20px" }}>
                                <div className="card card-3d" style={{ "border-radius": "8px", "padding": "10px", "padding-top":"20px", "background": "url(../images/dttm/card_background.png)" }}>
                                    <div className="row">
                                        <div className="twelve columns" >
                                            <h2 className="guestH2">DJ & BEATMAKER</h2>
                                        </div>
                                    </div>
                                    <div className="row align-center">

                                        <div className="six columns align-center">
                                            <img src={photo_dj_collo} className="photoGuest" alt="Dj Collo" style={{ "max-width": "45%", "padding-top": "20px" }} />
                                            <h2 style={{ "text-align": "center" }}>Collo</h2>
                                        </div>
                                        <div className="six columns">
                                            <img src={photo_yoshi} className="photoGuest" alt="Yoshi" style={{ "max-width": "45%", "padding-top": "20px" }} />
                                            <h2 style={{ "text-align": "center" }}>Yoshi Da Shaman</h2>
                                        </div>

                                        <div className="row">
                                            <div className="twelve columns align-center" style={{ "padding": "30px", paddingTop:"10px" }} >
                                                <h3 className="textShadowBlackLight" style={{fontSize:"15px"}}>Nel battle di hip hop ogni sfida dai quarti sarà a doppia entrata: una su traccia rap suonata da DJ Collo e una su un beat inedito di Yoshi Da Shaman.</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="two columns"></div>
                        </div>
                    </div>


                    {/*  ---------------MOBILE ---------------*/}
                    <div id="battle_mobile">
                        <div className="row">
                            <div className="three columns"></div>
                            <div className="six columns">
                                <div className="card card-3d" style={{ "border-radius": "8px", "padding": "5px", "background": "url(../images/dttm/card_background.png)" }}>
                                    <div className="row">
                                        <div className="twelve columns" style={{ "text-align": "center" }}>
                                            <h2 className="textShadowBlackLight">Giuria</h2>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="twelve columns" style={{ "text-align": "center" }}>
                                            <img src={photo_giuria} className="photoGuest" alt="Giuria DTTM" style={{ "max-width": "80%" }} />
                                            <h2 style={{ "text-align": "center", "font-size": "17px" }}>Cresh - Still - Glo' - Frenkyz</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="three columns"></div>
                        </div>

                        <div className="row">
                           
                            {/* categorie */}
                            <div className="four columns categorie">
                                <div className="card card-3d guestCard" style={{ "border-radius": "8px", "padding": "10px", "padding-top":"30px", "background": "url(../images/dttm/card_background.png)" }}>
                                    <div className="align-center" >
                                    <h2 className="dttmFontGrey textShadowBlackLight"> 🗓️ 26 Aprile 2024</h2>
                                        <h2 className="dttmFontGrey textShadowBlackLight"> ⏰ Inizio Check-In: <br/>Ore 15.00</h2>
                                        <h3 className="dttmFontGrey textShadowBlackLight"> ⏰ Inizio Categorie Under: <br/>Ore 16.00</h3>
                                        <h3 className="dttmFontGrey textShadowBlackLight"> ⏰ Inizio Categorie Over: <br/>Ore 17.30</h3>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="four columns categorie">
                                <div className="card card-3d guestCard" style={{ "border-radius": "8px", "padding": "10px", "padding-top":"30px", "background": "url(../images/dttm/card_background.png)" }}>
                                    <div className="align-center" >
                                  
                                        <h2 style={{ color: "#fff", fontFamily: 'oakland-raiders' , fontSize:"30px", paddingBottom:"5px"}} className="textShadowBlack">Categorie</h2>
                                        <h3 className="textShadowBlackLight"> • 1vs1 Mixstyle <br /> (Under 12) <br /> <text style={{ "font-size": "15px" }}>su musica Hip Hop</text></h3>
                                        <h3 className="textShadowBlackLight"> • 1vs1 Mixstyle <br /> (Under 16) <br /> <text style={{ "font-size": "15px" }}>su musica Hip Hop</text></h3>
                                        <h3 className="textShadowBlackLight"> • 1vs1 HipHop </h3>
                                        <h3 className="textShadowBlackLight"> • 1vs1 House </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="two column"></div>
                        </div>

                        <div className="row" style={{ "margin-top": "20px" }}>
                            <div className="two column"></div>
                            <div className="eight columns">
                                <div className="card card-3d card-iscrizione" style={{ "border-radius": "8px", "padding": "10px", "background": "#3c030b7d" }}>
                                    <div className="align-center" >
                                        
                                        <h5 className="textShadowBlackLight" style={{ "color": "white" }}>Le iscrizioni <u>ONLINE</u> sono chiuse.</h5>
                                        <h6 className="textShadowBlackLight" style={{ "color": "white" }}> <br/>Sarà comunque possibile iscriversi sul posto <br/> a partire dalle ore 15.00.</h6>
                                        <br/>
                                        <h6 className="textShadowBlackLight" style={{ "color": "white" }}>💵 ISCRIZIONE BATTLE GRATUITA</h6>
                                        {/*<h6 className="textShadowBlackLight" style={{"color":"white", marginTop: "10px"}}>📝  Per richiedere la <u>partecipazione</u> inviare una mail a: <a target="_blank" style={{color:"#b2b2b2"}} href="mailto: dancetothemusicmontalto@gmail.com">dancetothemusicmontalto@gmail.com</a></h6>
                                    <h6 className="textShadowBlackLight" style={{"color":"white", marginTop: "10px"}} >🗂️  Per il <u>regolamento</u> inviare una mail a: <a target="_blank" style={{color:"#b2b2b2"}} href="mailto: dancetothemusicmontalto@gmail.com">dancetothemusicmontalto@gmail.com</a> </h6>*/}
                                        <h6 className="textShadowBlackLight" style={{ "color": "white", marginTop: "10px" }}>🎟️  Ingresso per pubblico e ballerini: 15€</h6>
                                        {/*<a style={{ paddingLeft: "0%" }} href="/dancetothemusic_iscrizioni_battle"><button className="btnFileCoreo" style={{ marginTop: "20px" }}>Iscrizione</button></a><br />*/}
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="two column"></div>
                        </div>


                        <div className="row">
                            <div className="two columns"></div>
                            <div className="eight columns" style={{ "margin-top": "20px" }}>
                                <div className="card card-3d" style={{ "border-radius": "8px", "padding": "5px", "background": "url(../images/dttm/card_background.png)" }}>
                                    <div className="row">
                                        <div className="twelve columns" >
                                            <h2 className="guestH2">DJ & BEATMAKER</h2>
                                        </div>
                                    </div>
                                    <div className="row align-center">

                                        <div className="six columns align-center">
                                            <img src={photo_djbeatmaker} className="photoGuest" alt="Dj Collo Yoshi" style={{  "padding-top": "20px" }} />
                                            <h2 style={{ "text-align": "center" , fontSize:"17px"}}>Collo - Yoshi Da Shaman</h2>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="twelve columns align-center" style={{ "padding": "30px","padding-top":"10px" }} >
                                                <h3 style={{fontSize:"15px"}} className="textShadowBlackLight">Nel battle di hip hop ogni sfida dai quarti sarà a doppia entrata: una su traccia rap suonata da DJ Collo e una su un beat inedito di Yoshi Da Shaman.</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="two columns"></div>
                        </div>
                    </div>

                </Fade>
            </section >
        );
    }
}

export default Battle;
