import React, { Component } from "react";
import Fade from "react-reveal";
import Card from 'react-bootstrap/Card';
import { ZoomImage } from "../ZoomImage";

class Coreo extends Component {
    state = { isOpen: false };

    handleShowDialog = () => {
        this.setState({ isOpen: !this.state.isOpen });
        console.log("cliked");
    };

    render() {
        if (!this.props.data) return null;

        const img_coreo = "images/dttm/" + this.props.data.coreo.img_riquadro_iscrizioni;
        const photo_giuria_coreo = "images/" + this.props.data.dttm.photo_giuria_coreo;
        const file_regolamento = "file/regolamento_dttm_2024.pdf";

        return (
            <section id="coreo" >
                <Fade duration={1000}>
                    <div className="row">
                        < div className="align-center">
                            <h1>
                                <span style={{ color: "#fff", textShadow: "2px 2px 2px #000", fontFamily: 'oakland-raiders' }}>Coreografico</span>
                            </h1>
                        </div>
                        <br />
                    </div>
                 
                    <div className="row">
                        <div className="four columns"></div>
                        <div className="four columns">
                            <div className="card card-3d" style={{"border-radius":"8px", "padding":"5px", "background": "url(../images/dttm/card_background.png)"}}>
                                <div className="row">
                                    <div className="twelve columns" style={{"text-align":"center"}}>
                                        <h2 className="textShadowBlackLight">Giuria</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="twelve columns" style={{"text-align":"center"}}>
                                        <img src={photo_giuria_coreo} className="photoGuest" alt="Giuria DTTM" style={{ "max-width": "80%" }} /> 
                                    <h2 style={{"text-align":"center", "font-size": "22px"}}>Still - Glo' - Miss G</h2>
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div className="four columns"></div>
                    </div>
                    <div className="row" style={{"margin-top":"20px"}}>
                        <div className="two column"></div>
                        {/* info */}
                        <div className="four columns">
                            <div className="card card-3d" style={{"border-radius":"8px", "padding":"38px 10px 38px 10px", "background": "url(../images/dttm/card_background.png)"}}>
                                <div className="align-center" >
                                    <h2 className="dttmFontGrey textShadowBlackLight"> 🗓️ 25 Aprile 2024</h2>
                                    <h2 className="dttmFontGrey textShadowBlackLight"> ⏰ Ora Inizio: 15.00</h2>
                                </div>
                            </div>
                        </div>
                        {/* categorie */}
                        <div className="four columns categorie">
                            <div className="card card-3d" style={{"border-radius":"8px", "padding":"10px", "background": "url(../images/dttm/card_background.png)"}}>
                                <div className="align-center" >
                                    <h2 style={{ color: "#fff", fontFamily: 'oakland-raiders' }} className="textShadowBlack">Categorie</h2>
                                    <h3 className="textShadowBlackLight"> Cuccioli (Under 12) </h3>
                                    <h3 className="textShadowBlackLight"> Pischelli (Under 16)</h3>
                                    <h3 className="textShadowBlackLight"> Seri (Over 16)</h3>
                                </div>
                            </div>
                        </div>
                        <div className="two column"></div>
                    </div>
                    <div className="row" style={{"margin-top":"20px"}}>
                        <div className="one column"></div>
                        <div className="ten columns">
                            <div className="card card-3d card-iscrizione" style={{"border-radius":"8px", "padding":"10px", "background":"#3c030b7d"}}>
                                <div className="align-center" >
                                    <h5 className="textShadowBlackLight" style={{"color":"white"}}>ISCRIZIONI CHIUSE</h5>
                                    {/*<h6 className="textShadowBlackLight" style={{"color":"white", marginTop: "10px"}}>📝  Per richiedere la <u>partecipazione</u> inviare una mail a: <a target="_blank" style={{color:"#b2b2b2"}} href="mailto: dancetothemusicmontalto@gmail.com">dancetothemusicmontalto@gmail.com</a></h6>
                                    <h6 className="textShadowBlackLight" style={{"color":"white", marginTop: "10px"}} >🗂️  Per il <u>regolamento</u> inviare una mail a: <a target="_blank" style={{color:"#b2b2b2"}} href="mailto: dancetothemusicmontalto@gmail.com">dancetothemusicmontalto@gmail.com</a> </h6>*/}
                                    <h6 className="textShadowBlackLight" style={{"color":"white"}}>🎟️  <u>Costo biglietto</u> ingresso pubblico: 15€</h6>
                                    <a href={file_regolamento} download="Regolamento Dance To The Music 2024"><button className="btnFileCoreo" >Scarica Regolamento</button></a>
                                    {/*<a style={{ paddingLeft: "0%" }} href="/dancetothemusic_iscrizioni_coreo"><button className="btnFileCoreo" style={{ marginTop: "20px" }}>Richiesta di iscrizione</button></a><br /> */}
                                </div>
                            </div>
                        </div>
                        <div className="one column"></div>
                    </div>
                   
                </Fade>
            </section >
        );
    }
}

export default Coreo;
