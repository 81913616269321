export const ZoomImage = ({ title, largeImage, smallImage }) => {
    return (
      <div className='portfolio-item'>
        <div className=''>
          {' '}
          
            <div className=''>
              <h4>{title}</h4>
            </div>
            <img
              src={smallImage}
              className='img-responsive'
              alt={title}
            />{' '}
          
        </div>
      </div>
    )
  }