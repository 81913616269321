import React, { Component } from "react";
import Fade from "react-reveal";

class Battle extends Component {
    state = { isOpen: false };

    handleShowDialog = () => {
        this.setState({ isOpen: !this.state.isOpen });
        console.log("cliked");
    };

    render() {
        if (!this.props.data) return null;

        const logo_calendario = "images/" + this.props.data.loghi.calendario;

        return (
            <section id="date_ce" >
                <Fade duration={1000}>
                    <div className="row align-center">
                        < div className="align-center">
                            <h1>
                                <span style={{ color: "white" }} className="shadowtext-1b">DATE</span>
                            </h1>
                        </div>
                    </div>
                    <h3 className="align-center shadowtext-1b"> Il centro estivo si articolerà <br /> in 3 turni: </h3>
                    <br />

                    <div className="row" id="turniCe_mobile">
                        <div className="twelve columns align-center">
                            <div id="date_slide" className="carousel slide" data-bs-ride="carousel">

                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#date_slide" data-bs-slide-to="0" className="active"></button>
                                    <button type="button" data-bs-target="#date_slide" data-bs-slide-to="1"></button>
                                    <button type="button" data-bs-target="#date_slide" data-bs-slide-to="2"></button>
                                </div>

                                <div className="carousel-inner">

                                    <div className="carousel-item active turno1" >
                                       
                                            {/*<div class="ribbon ribbon-top-left"><span>SOLD OUT</span></div>*/}
                                            <img src={logo_calendario} alt="Calendario" style={{ width: "30%" }} />

                                            <h1 style={{ color: "white" }} className="shadowtext-1b"> PRIMO <br />TURNO </h1>
                                            <h3>10 Giugno - 21 Giugno</h3>
                                        
                                    </div>
                                    <div className="carousel-item turno2">
                                        {/*<div class="ribbon ribbon-top-left"><span>SOLD OUT</span></div>*/}
                                        <img src={logo_calendario} alt="Calendario" style={{ width: "30%" }} />

                                        <h1 style={{ color: "white" }} className="shadowtext-1b"> SECONDO <br />TURNO </h1>
                                        <h3>24 Giugno - 5 Luglio</h3>

                                    </div>
                                    <div className="carousel-item turno3">
                                        <img src={logo_calendario} alt="Calendario" style={{ width: "27%" }} />
                                        <h2 style={{ color: "white" }}> TERZO <br />TURNO*</h2>
                                        <h3>8 Luglio - 19 Luglio</h3>
                                        <h6 style={{ "line-height":"15px", color: "#fff"}}>*solo al raggiungimento del numero minimo di iscrizioni</h6>
                                    </div>
                                    
                                </div>

                                <button className="carousel-control-prev" type="button" data-bs-target="#date_slide" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon"></span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#date_slide" data-bs-slide="next">
                                    <span className="carousel-control-next-icon"></span>
                                </button>
                            </div>
                        </div>
                    </div>


                    <div className="row" id="turniCe_web">
                        <div className="four columns align-center">
                            <div class="box">
                                {/*<div class="ribbon ribbon-top-left"><span>SOLD OUT</span></div>*/}
                                <div className="turno1">
                                    <img src={logo_calendario} alt="Los Angeles" style={{ width: "30%" }} />
                                    <h1 style={{ color: "white" }}> PRIMO <br />TURNO </h1>
                                    <h3>10 Giugno - 21 Giugno</h3>
                                </div>
                            </div>
                        </div>

                        <div className="four columns align-center">
                            <div class="box">
                                {/*<div class="ribbon ribbon-top-left"><span>SOLD OUT</span></div>*/}
                                <div className="turno2">
                                    <img src={logo_calendario} alt="Los Angeles" style={{ width: "30%" }} />
                                    <h1 style={{ color: "white" }}> SECONDO <br />TURNO </h1>
                                    <h3>24 Giugno - 5 Luglio</h3>
                                </div>
                            </div>
                        </div>
                        <div className="four columns align-center">
                            <div className="turno3">
                                <img src={logo_calendario} alt="Los Angeles" style={{ width: "27%" }} />
                                <h2 style={{ color: "white" }}> TERZO <br />TURNO*</h2>
                                <h3>8 Luglio - 19 Luglio</h3>
                                <h6 style={{ "line-height":"15px", color: "#fff"}}>*solo al raggiungimento del numero minimo di iscrizioni</h6>
                            </div>
                        </div>
                    </div>
                    <br />
                    <h2 className="align-center shadowtext-2b" >Dal Lunedì al Venerdì <br />9,00 - 17,00 </h2>
                    <h3 className="align-center shadowtext-2b" >(con servizio di anticipo a richiesta dalle 8,00)</h3>

                </Fade>
            </section >
        );
    }
}

export default Battle;
