import React, { Component } from "react";
import Fade from "react-reveal";
import Card from 'react-bootstrap/Card';
import { ZoomImage } from "../ZoomImage";

class Stage extends Component {
    state = { isOpen: false };

    handleShowDialog = () => {
        this.setState({ isOpen: !this.state.isOpen });
        console.log("cliked");
    };

    render() {
        if (!this.props.data) return null;

        const img_coreo = "images/dttm/" + this.props.data.coreo.img_riquadro_iscrizioni;
        const file_regolamento = "file/regolamento_dttm_2024.pdf";

        return (
            <section id="stage" >
                <Fade duration={1000}>
                    <div className="row">
                        < div className="align-center">
                            <h1>
                                <span style={{ color: "#fff", textShadow: "2px 2px 2px #000", fontFamily: 'oakland-raiders' }}>Stage</span>
                            </h1>
                        </div>
                        <br />
                    </div>
                 

                    <div className="row">
                       
                    <div className="two column"></div>
                        {/* lezioni */}
                        <div className="eight columns categorie">
                            <div className="card card-3d guestCard" style={{"border-radius":"8px", "padding":"10px", "padding-bottom":"20px", "background":"#9900149c"}}>
                                <div className="align-center" >
                                <h2 className="dttmFontGrey textShadowBlackLight"> 🗓️ 25 Aprile 2024</h2>
                                <table>
                                    <tr className="textShadowBlackLight">
                                        <td >10.00 - 11.00</td>
                                        <td>Popping (Miss G)</td>
                                    </tr>
                                    <tr className="textShadowBlackLight">
                                        <td>11.00 - 12.00</td>
                                        <td>House (Glò)</td>
                                    </tr>
                                    <tr className="textShadowBlackLight">
                                        <td>12.00 - 13.00</td>
                                        <td>Hip Hop (Still) <text style={{"color":"red"}}>SOLD OUT</text></td>
                                    </tr>
                                </table>
                                </div>

                                <div className="align-center" >
                                    <h6 className="textShadowBlackLight" style={{"color":"white", marginTop: "30px"}}>💰 Il prezzo della lezione singola è 15€ <br/> 💰 Il prezzo di tutte e 3 le lezioni è 40€</h6>
                                    <h6 className="textShadowBlackLight" style={{"color":"white", marginTop: "30px"}}>📍 A.s.d. Sport Mix Due - Località arcipretura snc, 01014 Montalto di Castro VT</h6>
                                    
                                    {/*<a style={{ paddingLeft: "0%" }} href="/dancetothemusic_iscrizioni_stage"><button className="btnFileCoreo" style={{ marginTop: "20px" }}>Iscrizione</button></a><br /> */}
                                </div>
                            </div>
                            
                        </div>
                        <div className="two column"></div>
                    </div>
                    <div className="row align-center" style={{"margin-top":"20px"}}>
                        <div className="two column"></div>
                        <div className="eight columns">
                            <div card card-3d guestCard>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2948.9400529542804!2d11.614584275752843!3d42.34380143612909!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1328ebbc5c50b821%3A0xcfa62ab30016b7bb!2sA.s.d.%20Sport%20Mix%20Due!5e0!3m2!1sit!2sit!4v1707779935335!5m2!1sit!2sit" 
                             className="maps" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                               
                            </div>
                        </div>
                        <div className="one column"></div>
                    </div>
                   
                </Fade>
            </section >
        );
    }
}

export default Stage;
