import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";
import { ZoomImage } from "../ZoomImage";
import Collapse from 'react-bootstrap/Collapse';


class Workshop extends Component {
    constructor(props) {
        super(props);

        this.state = {
            collapseMenu: true,
            isOpen: false
        };

        this.showHide = this.showHide.bind(this);
    }

    showHide(e) {
        e.preventDefault();

        this.setState({
            collapseMenu: !this.state.collapseMenu
        });
    }


    handleShowDialog = () => {
        this.setState({ isOpen: !this.state.isOpen });
        console.log("cliked");
    };

    render() {
        if (!this.props.data) return null;

        const img_stage = "images/" + this.props.data.cypher_workshop.graphic_cypher_workshop;
        const logo_paypal = "images/" + this.props.data.loghi.paypal;
        const paypal = "https://Paypal.me/mcavalloro";

        return (
            <section id="workshop" style={{backgroundColor:"#19535f"}}> {/*4675a3d4*/}
            <Slide right duration={1000}>

                <Fade duration={1000}>
                    <div className="row">
                        < div className="align-center">
                            <h1 className="h1-little">
                                <span style={{ color: "white" }}>Iscrizione</span>
                            </h1>
                        </div><br />
                    </div>
                    <div className="row">
                        <div className="four columns">
                            <div id="iscrizione_div" className="align-center">
                                <ZoomImage className="img_iscrizioni" title="" largeImage={img_stage} smallImage={img_stage} />

                            </div>
                        </div><div className="eight columns main-col info-event" style={{ paddingLeft: "30px"}}>
                            
                        <div className="row">
                                <div className="columns contact-details">
                                    <h2 style={{textShadow: "#38aae1 2px 1px 6px", paddingTop:"5px"}}>24 FEBBRAIO 2024</h2>
                                </div>
                        </div>
                            <button
                                onClick={this.showHide}
                                className="btn btn-outline-info btn-lg"
                                type="button"
                                aria-expanded="false"
                            >
                                Come Funziona?
                            </button>

                            <Collapse in={!this.state.collapseMenu}>
                                <div className="card card-body" style={{backgroundColor:"#36727fcf", border:"3px solid #ffffff70", marginBottom:"15px"}}>
                                    <p style={{lineHeight: "30px", color: "#fff"}}>
                                        <b className="iconText">⏱️</b> <b>3 ore </b>di lezione<br />
                                        <b className="iconText">🎵</b> Dj set di <b>Dj Collo</b><br />
                                        <b className="iconText">⭕</b> I partecipanti potranno seguire le lezioni formando <b>4 cerchi</b><br />
                                        <b className="iconText">⏳</b> Ogni <b>45 minuti </b>il dj cambierà genere musicale, gli insegnanti cambieranno argomento tecnico e i ballerini potranno scegliere in quale cypher proseguire la lezione<br />
                                        <b className="iconText">📒</b> In questo modo ogni partecipante al workshop potrà <b>scegliere il proprio percorso</b>, andando a studiare ciò che più gli interessa.<br />
                                        <br/>
                                        <hr></hr>
                                        <b className="iconText">🎵</b> <u>Questi saranno i <b>generi </b>suonati da Dj Collo:</u>
                                        <br/>
                                        - LoFi<br/>
                                        - Boombap<br/>
                                        - West Coast<br/>
                                        - Detroit<br/><br/>
                                        <hr></hr>
                                        <b className="iconText">📝</b> <u>Questi saranno gli argomenti proposti dagli insegnanti:<br /></u>
                                        </p>
                                        <div id="workshopList" className="row" style={{ paddingTop: "10px", color:"white" }}>
                                            <div className="three columns"><b>Ibra:</b>
                                                <ul><li>LoFi: Legwork/Flow</li>
                                                    <li>Boombap: Toprock/Ritmica</li>
                                                    <li>West Coast: Drops/Levels</li>
                                                    <li>Detroit: Footwork/Knee Rock</li>
                                                </ul></div>
                                            <div className="three columns"><b>Elisa:</b>
                                                <ul><li>LoFi: Contatto</li>
                                                    <li>Boombap:Torsioni/Uso del busto</li>
                                                    <li>West Coast: Linee/Strutture</li>
                                                    <li>Detroit: Cambi energia</li>
                                                </ul></div>
                                            <div className="three columns"><b>Wally:</b>
                                                <ul><li>LoFi: Infinito 360</li>
                                                    <li>Boombap: Creativity Legs</li>
                                                    <li>West Coast: Twist/Heel Toe</li>
                                                    <li>Detroit: Braccia T.I.T.B/Box</li>
                                                </ul></div>
                                            <div className="three columns"><b>Marco:</b>
                                                <ul><li>LoFi: Ampiezza movimenti</li>
                                                    <li>Boombap: Appoggi</li>
                                                    <li>West Coast: Coordinazione spalle</li>
                                                    <li>Detroit: Spostamenti</li>
                                                </ul></div></div>

                                        <table id="workshopTable">
                                            <thead>
                                            <tr>
                                                <th></th>
                                                <th>IBRA</th>
                                                <th>ELISA</th>
                                                <th>WALLY</th>
                                                <th>MARCO</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td><b>LoFi</b></td>
                                                <td>Legwork/Flow</td>
                                                <td>Contatto</td>
                                                <td>Infinito 360</td>
                                                <td>Ampiezza movimenti</td>
                                            </tr>
                                            <tr>
                                                <td><b>Boombap</b></td>
                                                <td>Toprock/Ritmica</td>
                                                <td>Torsioni/Uso del busto</td>
                                                <td>Creativity Legs</td>
                                                <td>Appoggi</td>
                                            </tr>
                                            <tr>
                                                <td><b>West Coast</b></td>
                                                <td>Drops/Levels</td>
                                                <td>Linee/Strutture</td>
                                                <td>Twist/Heel Toe</td>
                                                <td>Coordinazione spalle</td>
                                            </tr>
                                            <tr>
                                                <td><b>Detroit</b></td>
                                                <td>Footwork/Knee Rock</td>
                                                <td>Cambi energia</td>
                                                <td>Braccia T.I.T.B/Box</td>
                                                <td>Spostamenti</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                                       </div>
                            </Collapse>


                            <div className="row">
                                <div className="columns contact-details">
                                    <div className="card card-3d" style={{"border-radius":"8px", "padding":"10px", background:"#0c3a44a6"}}>
                                        <p className="address" style={{color:"#fff"}}>
                                            <span><b><b className="iconText" style={{padding:0}}>⏱</b> Orario: </b>dalle 15 alle 18</span><br />
                                            
                                            <span><b className="iconText" style={{padding:0}}>💣</b> <b> Termine Iscrizioni: </b>18 Febbraio</span><br />
                                            <span><b className="iconText" style={{padding:0}}>💰</b> <b> Costo: </b>45€ <br/> 
                                           {/* <button className="btn btn-outline-info" style={{backgroundColor:"#fff", marginRight:"15px", marginLeft:"30px", marginTop:"5px"}}><a target="blank" href={paypal}>
                                            <img alt="paypal" src={logo_paypal} style={{ width: "70px"}} />
        </a></button>*/}
                                        </span><br />
                                            
                                            <span ><b className="iconText" style={{padding:0}}>📍</b> <b>Palestra Pegaso Fitness & Wellness </b> - Via Casaretta 166, 35013 Cittadella (PD) </span>
                                            
                                            </p>
                                            </div>
                                            {/*<span className="disclaimer" style={{ fontSize: "12px" }}>* Gli orari potrebbero subire variazioni che saranno tempestivamente comunicate al contatto lasciato nel form di iscrizione</span>*/}
                                          {/*  <div style={{ textAlign:"center", marginTop:"30px" }} class="align-center">
                                            <a  href="cypherworkshop/iscrizioni"><button style={{ backgroundColor: "#38aae1", paddingLeft:"10px", paddingRight:"10px", borderRadius:"8px" }}>Iscriviti</button></a>  
                                            </div>
        */}
                                        

                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
                </Slide>
            </section >
        );
    }
}

export default Workshop;
