import React, { Component } from "react";
import Fade from "react-reveal";
import { Programma_section1 } from "./Programma_section1";
import { Programma_section2 } from "./Programma_section2";

class Programma extends Component {
    state = { isOpen: false };

    handleShowDialog = () => {
        this.setState({ isOpen: !this.state.isOpen });
        console.log("cliked");
    };

    render() {
        if (!this.props.data) return null;
        return (
            <section id="programma" >
                <Fade duration={1000}>
                    <div className="row">
                        < div className="align-center">
                            <h1 style={{  marginBottom:"0px"}}>
                                <span className="shadowtext-1b" style={{ color: "white" }}>PROGRAMMA 1° TURNO</span>
                            </h1>
                            <h4>
                                <i><span className="shadowtext-1b" style={{ color: "white" }}>Il programma per i turni successivi è scaricabile nella sezione <a href="#info" style={{color:"#f5a004", textDecoration: "underline"}}>Info</a></span></i>
                            </h4>
                        </div>
                    </div>
                    <br />

                    <div className="row" id="programma_mobile">
                        <div className="twelve columns align-center">
                            <div id="programma_slide" className="carousel slide" data-bs-interval="false">

                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#programma_slide" data-bs-slide-to="0" className="active"></button>
                                    <button type="button" data-bs-target="#programma_slide" data-bs-slide-to="1"></button>
                                </div>

                                <div className="carousel-inner">
                                    <div className="carousel-item active attivita-list2" style={{ height: "1080px" }}>
                                        <Programma_section1 />
                                    </div>
                                    <div className="carousel-item attivita-list2" style={{ height: "1080px" }}>
                                        <Programma_section2 />
                                    </div>
                                </div>

                                <button className="carousel-control-prev" type="button" data-bs-target="#programma_slide" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon"></span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#programma_slide" data-bs-slide="next">
                                    <span className="carousel-control-next-icon"></span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div id="programma_web">
                        <div className="attivita-list2 align-center" style={{width: "1205px"}}>
                                <h2 className="shadowtext-1b" style={{ color: "#f6a000", paddingBottom: "10px" }}>1° SETTIMANA</h2>
                                <div style={{ display: "inline-table", paddingLeft: "10px", paddingRight: "10px",borderRight:"2px solid white" }}>
                                    <h3>――<b className="iconActivity2 nobr">Lunedì</b>――</h3>
                                    <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Palestra Sport Mix Due <br />
                                        <text className="shadowtext-1b">Pranzo al sacco </text><br />
                                        <text className="shadowtext-1b">Pomeriggio:</text> Campo Martelli</h3>
                                    <h3>Consegna e ritiro bambini <br /> al Palazzetto dello Sport</h3>
                                </div>
                                <div style={{ display: "inline-table", paddingLeft: "10px", paddingRight: "10px", borderRight:"2px solid white" }}>
                                    <h3>――――<b className="iconActivity2">Martedì</b>――</h3>
                                    <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Pineta; attività con i cani<br />
                                        <text className="shadowtext-1b">Pranzo: </text> Garden Club<br />
                                        <text className="shadowtext-1b">Pomeriggio:</text> Cambusiero</h3>
                                    <h3>Consegna e ritiro bambini <br />al Garden Club</h3>
                                </div>
                                <div style={{ display: "inline-table", paddingLeft: "10px", paddingRight: "10px", borderRight:"2px solid white" }}>
                                    <h3>――<b className="iconActivity2">Mercoledì</b>――</h3>
                                    <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Palestra Sport Mix Due; English Games <br />
                                        <text className="shadowtext-1b">Pranzo al sacco </text><br />
                                        <text className="shadowtext-1b">Pomeriggio:</text> Campo Martelli</h3>
                                    <h3>Consegna e ritiro bambini <br />al Palazzetto dello Sport</h3>
                                </div>
                            
                                <div style={{ display: "inline-table", paddingLeft: "10px", paddingRight: "10px", borderRight:"2px solid white" }}>
                                    <h3>――<b className="iconActivity2">Giovedì</b>――</h3>
                                    <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Pineta<br />
                                        <text className="shadowtext-1b">Pranzo al sacco </text><br />
                                        <text className="shadowtext-1b">Pomeriggio:</text> Cambusiero</h3>
                                    <h3>Consegna e ritiro bambini <br />al Garden Club</h3>
                                </div>
                                <div style={{ display: "inline-table", paddingLeft: "10px", paddingRight: "10px"}}>
                                    <h3>――<b className="iconActivity2">Venerdì</b>――</h3>
                                    <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Centro ippico Ruben Ranch<br />
                                        <text className="shadowtext-1b">Pranzo al sacco </text><br />
                                        <text className="shadowtext-1b">Pomeriggio:</text> Mare</h3>
                                    <h3>Consegna bambini al <br />Centro ippico Ruben Ranch<br />
                                        Ritiro bambini "da comunicare"</h3>
                                </div>
                        </div>

                        <div className="attivita-list2 align-center" style={{width: "1205px"}}>
                                <h2 className="shadowtext-1b" style={{ color: "rgb(0 217 255)", paddingBottom: "10px" }}>2° SETTIMANA</h2>
                                <div style={{ display: "inline-table", paddingLeft: "10px", paddingRight: "10px",borderRight:"2px solid white" }}>
                                    <h3>――<b className="iconActivity3">Lunedì</b>――</h3>
                                    <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Piani degli Alpaca <br />
                                        <text className="shadowtext-1b">Pranzo al sacco </text><br />
                                        <text className="shadowtext-1b">Pomeriggio:</text> Piani degli Alpaca</h3>
                                    <h3>Consegna e ritiro bambini ai <br/>Piani degli Alpaca</h3>
                                </div>
                                <div style={{ display: "inline-table", paddingLeft: "10px", paddingRight: "10px", borderRight:"2px solid white" }}>
                                    <h3>――――<b className="iconActivity3">Martedì</b>――</h3>
                                    <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text>Pineta<br />
                                        <text className="shadowtext-1b">Pranzo: </text> Garden Club<br />
                                        <text className="shadowtext-1b">Pomeriggio:</text> Cambusiero</h3>
                                    <h3>Consegna e ritiro bambini al<br/> Garden Club</h3>
                                </div>
                                <div style={{ display: "inline-table", paddingLeft: "10px", paddingRight: "10px", borderRight:"2px solid white" }}>
                                    <h3>――<b className="iconActivity3">Mercoledì</b>――</h3>
                                    <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Palestra Sport Mix Due <br />
                                        <text className="shadowtext-1b">Pranzo al sacco </text><br />
                                        <text className="shadowtext-1b">Pomeriggio:</text> Campo Martelli</h3>
                                    <h3>Consegna e ritiro bambini <br />al Palazzetto dello Sport</h3>
                                </div>
                            
                                <div style={{ display: "inline-table", paddingLeft: "10px", paddingRight: "10px", borderRight:"2px solid white" }}>
                                    <h3>――<b className="iconActivity3">Giovedì</b>――</h3>
                                    <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Pineta; English Game<br />
                                        <text className="shadowtext-1b">Pranzo al sacco </text><br />
                                        <text className="shadowtext-1b">Pomeriggio:</text> Cambusiero</h3>
                                    <h3>Consegna e ritiro bambini al<br/> Garden Club</h3>
                                </div>
                                <div style={{ display: "inline-table", paddingLeft: "10px", paddingRight: "10px"}}>
                                    <h3>――<b className="iconActivity3">Venerdì</b>――</h3>
                                    <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Parco Archeologico di Vulci<br />
                                        <text className="shadowtext-1b">Pranzo: </text> Casaletto Mengarelli<br />
                                        <text className="shadowtext-1b">Pomeriggio:</text> Laghetto di Vulci</h3>
                                    <h3>Consegna e ritiro bambini al <br/>Parco Archeologico di Vulci</h3>
                                </div>
                        </div>
{/*                         <div className="row attivita-list2">
                           <div className="row">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="align-center">Lunedì</th>
                                            <th className="align-center">Martedì</th>
                                            <th className="align-center">Mercoledì</th>
                                            <th className="align-center">Giovedì</th>
                                            <th className="align-center">Venerdì</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr >
                                            <td className="align-center">
                                                <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Palestra Sport Mix Due <br />
                                                    <text className="shadowtext-1b">Pranzo al sacco </text><br />
                                                    <text className="shadowtext-1b">Pomeriggio:</text> Campo Martelli</h3>
                                                <h3>Consegna e ritiro bambini <br /> al Palazzetto dello Sport</h3>
                                            </td>

                                            <td className="align-center">
                                                <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text>Pineta; attività con i cani<br />
                                                    <text className="shadowtext-1b">Pranzo: </text> Garden Club<br />
                                                    <text className="shadowtext-1b">Pomeriggio:</text> Cambusiero</h3>
                                                <h3>Consegna e ritiro bambini <br />al Garden Club</h3>
                                            </td>
                                            <td className="align-center">
                                                <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Palestra Sport Mix Due; English Games <br />
                                                    <text className="shadowtext-1b">Pranzo al sacco </text><br />
                                                    <text className="shadowtext-1b">Pomeriggio:</text> Campo Martelli</h3>
                                                <h3>Consegna e ritiro bambini <br />al Palazzetto dello Sport</h3>
                                            </td>
                                            <td className="align-center">
                                                <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Pineta<br />
                                                    <text className="shadowtext-1b">Pranzo al sacco </text><br />
                                                    <text className="shadowtext-1b">Pomeriggio:</text> Cambusiero</h3>
                                                <h3>Consegna e ritiro bambini <br />al Garden Club</h3>
                                            </td>
                                            <td className="align-center">
                                                <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Centro ippico Ruben Ranch<br />
                                                    <text className="shadowtext-1b">Pranzo al sacco </text><br />
                                                    <text className="shadowtext-1b">Pomeriggio:</text> Mare</h3>
                                                <h3>Consegna bambini al <br />Centro ippico Ruben Ranch<br />
                                                    Ritiro bambini "da comunicare"</h3>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
        </div> 
                            <div className="row align-center">
                                <h2 className="shadowtext-1b" style={{ color: "#f6a000", paddingBottom: "10px" }}>1° SETTIMANA</h2>
                                <div style={{ display: "inline-table", paddingLeft: "20px", paddingRight: "20px" }}>
                                    <h3>―――――<b className="iconActivity2">Lun</b>―――――</h3>
                                    <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Palestra Sport Mix Due <br />
                                        <text className="shadowtext-1b">Pranzo al sacco </text><br />
                                        <text className="shadowtext-1b">Pomeriggio:</text> Campo Martelli</h3>
                                    <h3>Consegna e ritiro bambini <br /> al Palazzetto dello Sport</h3>
                                </div>
                                <div style={{ display: "inline-table", paddingLeft: "20px", paddingRight: "20px" }}>
                                    <h3>―――――<b className="iconActivity2">Mar</b>―――――</h3>
                                    <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text>Pineta; attività con i cani<br />
                                        <text className="shadowtext-1b">Pranzo: </text> Garden Club<br />
                                        <text className="shadowtext-1b">Pomeriggio:</text> Cambusiero</h3>
                                    <h3>Consegna e ritiro bambini <br />al Garden Club</h3>
                                </div>
                                <div style={{ display: "inline-table", paddingLeft: "20px", paddingRight: "20px" }}>
                                    <h3>―――――<b className="iconActivity2">Mer</b>―――――</h3>
                                    <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Palestra Sport Mix Due; English Games <br />
                                        <text className="shadowtext-1b">Pranzo al sacco </text><br />
                                        <text className="shadowtext-1b">Pomeriggio:</text> Campo Martelli</h3>
                                    <h3>Consegna e ritiro bambini <br />al Palazzetto dello Sport</h3>
                                </div>
                            </div>
                            <div className="row align-center" style={{ paddingTop: "30px" }}>
                                <div style={{ display: "inline-table", paddingLeft: "20px", paddingRight: "20px" }}>
                                    <h3>―――――<b className="iconActivity2">Gio</b>―――――</h3>
                                    <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Pineta<br />
                                        <text className="shadowtext-1b">Pranzo al sacco </text><br />
                                        <text className="shadowtext-1b">Pomeriggio:</text> Cambusiero</h3>
                                    <h3>Consegna e ritiro bambini <br />al Garden Club</h3>
                                </div>
                                <div style={{ display: "inline-table", paddingLeft: "20px", paddingRight: "20px" }}>
                                    <h3>―――――<b className="iconActivity2">Ven</b>―――――</h3>
                                    <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Centro ippico Ruben Ranch<br />
                                        <text className="shadowtext-1b">Pranzo al sacco </text><br />
                                        <text className="shadowtext-1b">Pomeriggio:</text> Mare</h3>
                                    <h3>Consegna bambini al <br />Centro ippico Ruben Ranch<br />
                                        Ritiro bambini "da comunicare"</h3>
                                </div>
                            </div>
                        </div> */}

{/*                        <div className="row attivita-list2">
                            <div className="row align-center">
                                <h2 className="shadowtext-1b" style={{ color: "rgb(0 217 255)", paddingBottom: "10px" }}>2° SETTIMANA</h2>
                                <div style={{ display: "inline-table", paddingLeft: "20px", paddingRight: "20px" }}>
                                    <h3>―――――<b className="iconActivity3">Lun</b>―――――</h3>
                                    <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Piani degli Alpaca <br />
                                        <text className="shadowtext-1b">Pranzo al sacco </text><br />
                                        <text className="shadowtext-1b">Pomeriggio:</text> Piani degli Alpaca</h3>
                                    <h3>Consegna e ritiro bambini ai Piani degli Alpaca</h3>
                                </div>
                                <div style={{ display: "inline-table", paddingLeft: "20px", paddingRight: "20px" }}>
                                    <h3>―――――<b className="iconActivity3">Mar</b>―――――</h3>
                                    <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text>Pineta<br />
                                        <text className="shadowtext-1b">Pranzo: </text> Garden Club<br />
                                        <text className="shadowtext-1b">Pomeriggio:</text> Cambusiero</h3>
                                    <h3>Consegna e ritiro bambini al Garden Club</h3>
                                </div>
                                <div style={{ display: "inline-table", paddingLeft: "20px", paddingRight: "20px" }}>
                                    <h3>―――――<b className="iconActivity3">Mer</b>―――――</h3>
                                    <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Palestra Sport Mix Due; English Games <br />
                                        <text className="shadowtext-1b">Pranzo al sacco </text><br />
                                        <text className="shadowtext-1b">Pomeriggio:</text> Campo Martelli</h3>
                                    <h3>Consegna e ritiro bambini <br />al Palazzetto dello Sport</h3>
                                </div>
                            </div>
                            <div className="row align-center" style={{ paddingTop: "30px" }}>
                                <div style={{ display: "inline-table", paddingLeft: "20px", paddingRight: "20px" }}>
                                    <h3>―――――<b className="iconActivity3">Gio</b>―――――</h3>
                                    <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> English Game<br />
                                        <text className="shadowtext-1b">Pranzo al sacco </text><br />
                                        <text className="shadowtext-1b">Pomeriggio:</text> Cambusiero</h3>
                                    <h3>Consegna e ritiro bambini al Garden Club</h3>
                                </div>
                                <div style={{ display: "inline-table", paddingLeft: "20px", paddingRight: "20px" }}>
                                    <h3>―――――<b className="iconActivity3">Ven</b>―――――</h3>
                                    <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Parco Archeologico di Vulci<br />
                                        <text className="shadowtext-1b">Pranzo: </text> Casaletto Mengarelli<br />
                                        <text className="shadowtext-1b">Pomeriggio:</text> Laghetto di Vulci</h3>
                                    <h3>Consegna bambini al Parco Archeologico di Vulci<br />
                                        Ritiro bambini "da comunicare"</h3>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <h5 className="shadowtext-1b align-center">
                        N.B. Il programma potrebbe subire variazioni in base al clima o esigenze dell’organizzazione. <br />Qualsiasi cambiamento verrà comunicato tempestivamente tramite telefono.
                    </h5>



                </Fade>
            </section >
        );
    }
}

export default Programma;
