import React, { Component } from "react";
import Fade from "react-reveal";

class Media extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const profilepic = "images/" + this.props.data.image;
    const bio = this.props.data.bio;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const email = this.props.data.email;
    const resumeDownload = this.props.data.resumedownload;

    const general_desc = this.props.data.ordinary_lab.general_desc;
    const hiphop_desc = this.props.data.ordinary_lab.hiphop_desc;
    const hiphop_photo = "images/" + this.props.data.ordinary_lab.choice_hip_hop;

    return (
      <section id="media">
        <Fade duration={1000}>
        <div className="row align-center">
            < div className="align-center">
                <h1>
                    <span style={{ color: "white" }} className="shadowtext-1b"><u>MEDIA</u></span>
                </h1>
            </div>
        </div>

        <div style={{"text-align":"center"}}>
                <h2>Recap dell'ultimo Ordinary LAB</h2>
                <div class="container">
      <div className="embed-responsive embed-responsive-16by9">
        <iframe className="embed-responsive-item" src="https://player.vimeo.com/video/750790196?h=1bd4baf6ab" width="400" height="260" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
                
                            </div>
        </Fade>
      </section>
    );
  }
}

export default Media;
