import React, { Component } from "react";
import Fade from "react-reveal";
import $ from 'jquery';

class Guests extends Component {

    render() {
        if (!this.props.data) return null;

        const photo_giuria_coreo = "images/" + this.props.data.dttm.photo_giuria_coreo;
        const photo_giuria_hiphop = "images/" + this.props.data.dttm.photo_giuria_hiphop;
        const photo_giuria_house  = "images/" + this.props.data.dttm.photo_giuria_house;
        const photo_host_marco = "images/"+  this.props.data.dttm.photo_host_marco;
        const photo_host_wally = "images/"+  this.props.data.dttm.photo_host_wally;
        const photo_dj_collo = "images/"+  this.props.data.dttm.photo_dj_collo;
        const photo_yoshi = "images/"+ this.props.data.dttm.photo_yoshi;
        return (
            <section id="guests">
                <Fade duration={1000}>

                    <div className="resume_web" id="resume_web">
                        <div className="guestH2" style={{"text-align":"center"}}>
                            <h2 >GUEST</h2>
                        </div>
                    <div className="row">
                        <div className="four columns">
                            <div className="card guestCard" >
                                <div className="row">
                                    <div className="twelve columns">
                                        <h2 className="guestH2">GIURIA COREOGRAFICO</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="twelve columns" style={{"text-align":"center"}}>
                                        <img src={photo_giuria_coreo} className="photoGuest" alt="Giuria DTTM" style={{ "max-width": "80%", "padding-top":"20px" }} /> 
                                    <h2 style={{"text-align":"center", "font-size": "22px"}}>Still - Glo' - Miss G</h2>
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div className="four columns">
                            <div className="card guestCard" >
                                <div className="row">
                                    <div className="twelve columns">
                                        <h2 className="guestH2">GIURIA BATTLE HIP HOP</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="twelve columns" style={{"text-align":"center"}}>
                                        <img src={photo_giuria_hiphop} className="photoGuest" alt="Giuria DTTM" style={{ "max-width": "64%", "padding-top":"20px" }} /> 
                                    <h2 style={{"text-align":"center", "font-size": "22px"}}>Still - Cresh - Frenkyz</h2>
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div className="four columns">
                            <div className="card guestCard" >
                                <div className="row">
                                    <div className="twelve columns">
                                        <h2 className="guestH2">GIURIA BATTLE HOUSE</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="twelve columns" style={{"text-align":"center"}}>
                                        <img src={photo_giuria_house} className="photoGuest" alt="Giuria DTTM" style={{ "max-width": "75%", "padding-top":"20px" }} /> 
                                    <h2 style={{"text-align":"center", "font-size": "22px"}}>Cresh - Glo' - Still </h2>
                                    </div>
                                </div>
                            </div>    
                        </div>
                    </div>

                        {/*<div className="divider"></div>*/}
                    
                        <div className="row" style={{"margin-top":"30px"}}>
                            <div className="one column"></div>
                            <div className="four columns card guestCard">
                                <div className="row">
                                    <div className="twelve columns" >
                                        <h2 className="guestH2">DJ & BEATMAKER</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    
                                    <div className="six columns">
                                        <img src={photo_dj_collo} className="photoGuest" alt="Dj Collo"  />
                                        <h2 style={{"text-align":"center"}}>Collo</h2>
                                    </div>
                                    <div className="six columns">
                                        <img src={photo_yoshi} className="photoGuest" alt="Yoshi"  />
                                        <h2 style={{"text-align":"center"}}>Yoshi Da Shaman</h2>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="one column"></div>
                            <div className="five columns card guestCard">
                                <div className="row">
                                    <div className="twelve columns " >
                                        <h2 className="guestH2">HOST</h2>
                                    </div>
                                </div>
                                <div className="row align-center">
                                    <div className="six columns align-center">
                                        <img src={photo_host_marco} className="photoGuest" alt="Marco Cavalloro" style={{ "max-width": "65%" }} />
                                        <h2 style={{"text-align":"center"}}>Marco</h2>
                                    </div>
                                    <div className="six columns align-center">
                                        <img src={photo_host_wally} className="photoGuest" alt="Wally" style={{ "max-width":"84%" }} />
                                        <h2 style={{"text-align":"center"}}>Wally</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="one column"></div>
                        </div>
                    
                        
                    </div>


                    {/* MOBILE VERSIONE */}
                    <div className="guest resume_mobile" id="guest_resume_mobile">
                    <div className="row">
                        <div className="four columns" style={{ "margin-top":"40px" }}>
                            <div className="card guestCard" >
                                <div className="row">
                                    <div className="twelve columns">
                                        <h2 className="guestH2">Giuria <br/>Coreografico</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="twelve columns" style={{"text-align":"center"}}>
                                        <img src={photo_giuria_coreo} className="photoGuest" alt="Giuria DTTM" style={{  }} /> 
                                    <h2 style={{"text-align":"center", fontSize: "27px"}}>Still - Glo' - Miss G</h2>
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div className="four columns" style={{ "margin-top":"40px" }}>
                            <div className="card guestCard" >
                                <div className="row">
                                    <div className="twelve columns">
                                        <h2 className="guestH2">Giuria <br/>Battle Hip Hop</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="twelve columns" style={{"text-align":"center"}}>
                                        <img src={photo_giuria_hiphop} className="photoGuest" alt="Giuria DTTM" style={{  }} /> 
                                    <h2 style={{"text-align":"center", fontSize: "27px"}}>Still - Cresh - Frenkyz</h2>
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div className="four columns" style={{ "margin-top":"40px" }}>
                            <div className="card guestCard" >
                                <div className="row">
                                    <div className="twelve columns">
                                        <h2 className="guestH2">Giuria <br/>Battle House</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="twelve columns" style={{"text-align":"center"}}>
                                        <img src={photo_giuria_house} className="photoGuest" alt="Giuria DTTM" style={{  }} /> 
                                    <h2 style={{"text-align":"center", fontSize: "27px"}}>Cresh - Glo' - Still</h2>
                                    </div>
                                </div>
                            </div>    
                        </div>
                    </div>

                        <div className="row" style={{"margin-top":"40px"}}>
                            <div className="three columns"></div>
                            <div className="six columns">
                                <div className="card guestCard" >
                                    <div className="row">
                                        <div className="twelve columns">
                                            <h2 className="guestH2">DJ</h2>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="twelve columns" style={{"text-align":"center"}}>
                                            <img src={photo_dj_collo} className="photoGuest" alt="Dj Collo"  style={{ "max-width": "65%" }}/>
                                            <h2 style={{"text-align":"center"}}>Collo</h2>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                            <div className="three columns"></div>
                        </div>

                        <div className="row" style={{"margin-top":"40px"}}>
                            <div className="three columns"></div>
                            <div className="six columns">
                                <div className="card guestCard" >
                                    <div className="row">
                                        <div className="twelve columns">
                                            <h2 className="guestH2">BEATMAKER</h2>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="twelve columns" style={{"text-align":"center"}}>
                                            <img src={photo_yoshi} className="photoGuest" alt="Yoshi Da Shaman"  style={{ "max-width": "65%" }}/>
                                            <h2 style={{"text-align":"center"}}>Yoshi Da Shaman</h2>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                            <div className="three columns"></div>
                        </div>

                        <div className="row" style={{ "margin-top":"40px" }}>
                            <div className="three columns"></div>
                            <div className="six columns">
                                <div className="card guestCard" >
                                    <div className="row">
                                        <div className="twelve columns">
                                            <h2 className="guestH2">HOSTS</h2>
                                        </div>
                                    </div>
                                    <div className="row align-center">
                                        <div className="six columns align-center">
                                            <img src={photo_host_marco} className="photoGuest" alt="Marco Cavalloro" style={{ "max-width": "55%" }} />
                                            <h2 style={{"text-align":"center"}}>Marco</h2>
                                        </div>
                                        <div className="six columns align-center" style={{"margin-top":"40px"}}>
                                            <img src={photo_host_wally} className="photoGuest" alt="Wally" style={{ "max-width":"64%" }} />
                                            <h2 style={{"text-align":"center"}}>Wally</h2>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                            <div className="three columns"></div>
                        </div>
                    </div>

 {/*                   <div className="resume_mobile" id="resume_mobile">
                    <img src={foto_marco} alt="Marco Cavalloro" style={{ "max-width": "80%", "padding-top":"50px", "float":"right", "margin-right":"-90px"}} />
                    
                    <div className="row">
                        <div className="nine columns main-col" >
                            <h2>Marco Cavalloro</h2>
                            <p>{marco_bio_short} 
                            <br/><button type="text" id="bio_expand" onClick={this.showMore}>Continua..</button>
                            <p id="p_more_bio" style={{"display":"none"}}>{marco_bio_more}</p>
                            <button type="text" id="bio_reduce" onClick={this.showLess} style={{"display":"none"}}>Mostra meno..</button>
                            
                            <br/><a className="social-links" target="blank" style={{ marginRight: "15px" }} href={instagram_marco}>
                                <img alt="instagram" src={logo_instagram} style={{ width: "45px" }} />
                            </a>
                            <a className="social-links" target="blank" style={{ marginRight: "15px" }} href={vimeo_marco}>
                                <img alt="vimeo" src={logo_vimeo} style={{ width: "45px" }} />
                            </a>
                            </p>
                        </div>

                    </div>

                    <img src={foto_collo} alt="Dj Collo" style={{ "max-width": "80%", "padding-top":"50px", "float":"right", "margin-right":"-90px"}} />
                    <div className="row">
                        <div className="nine columns main-col">
                            <h2>Dj Collo</h2>
                            <p>{collo_bio}
                            <br/><a className="social-links" target="blank" style={{ marginRight: "15px" }} href={instagram_collo}>
                                <img alt="instagram" src={logo_instagram} style={{ width: "45px" }} />
                            </a>
                            </p>
                        </div>
                        
                        
                    </div>
                    </div>
        */}

                </Fade>
            </section>
        );
    }
}

export default Guests;
