import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";

class Info extends Component {
    render() {
        if (!this.props.data) return null;

        const whatsapp = this.props.data.whatsapp;
        const logo_whatsapp = "images/" + this.props.data.loghi.whatsapp;

        const file_programma_1 = "file/Programma_Centro_Estivo_2024.pdf";
        const file_iscrizione = "file/Modulo_iscrizione.pdf";
        const file_iscrizione_facsimile = "file/Facsimile_modulo_iscrizione.pdf";

        return (
            <section id="info" style={{ background: "url(images/centroestivo/foto_bambini_2.png)", backgroundSize: "cover" }}>
                <div className="row">
                    <Slide right duration={1000}>
                        <div className="row">
                            < div className="align-center">
                                <h1>
                                    <span style={{ color: "white" }} className="shadowtext-1b">INFO e PROGRAMMA</span>
                                </h1>
                            </div>
                        </div>

                        <Fade bottom>
                            <div className="align-center infoBox" >
                                <h3 className="shadowtext-1bl">
                                    Il prezzo del centro estivo è <b>250€.</b>
                                </h3>
                                <h6 style={{"color":"white"}}>
                                    Per effettuare l'iscrizione occorre un versamento del 50% della quota come caparra. <br/>
                                    <font style={{color:"yellow"}}>Sconto del 20% per fratelli e sorelle<br/> 
                                    Sconto del 20% per chi effettua più turni<br/>
                                    Promozione non cumulabile<br/></font>
                                    <u>I POSTI SONO LIMITATI</u>
                                </h6>
                                <br/>
                                <h3 className="shadowtext-1bl">Programma in PDF:</h3>
                                <a href={file_programma_1} download="Programma Centro Estivo 2024"><button className="btnFileCentro" >Scarica Programma</button></a>
                                {/*<a href={file_programma_2} download="Programma Centro Estivo 2024 - 2 Turno"><button className="btnFileCentro" >Scarica Programma 2° Turno</button></a>
                                <a href={file_programma_3} download="Programma Centro Estivo 2024 - 3 Turno"><button className="btnFileCentro" >Scarica Programma 3° Turno</button></a>*/}
                                <br /><br />
                                <h3 className="shadowtext-1bl">Ogni partecipante al centro dovrà compilare il modulo di iscrizione all'Asd Sport Mix Due:</h3>
                                <a href={file_iscrizione} download="Modulo Iscrizione Centro Estivo 2024"><button className="btnFileCentro" >Scarica Modulo Iscrizione</button></a>
                                {/* <a href={file_iscrizione_facsimile} download="FACSIMILE - Modulo Iscrizione Centro Estivo 2023"><button className="btnFileCentro" >Scarica Facsimile Modulo Iscrizione</button></a>*/}

                            </div>
                        </Fade>

                    </Slide>
                </div>
            </section>
        );
    }
}

export default Info;
