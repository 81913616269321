import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";

class Info extends Component {
    render() {
        if (!this.props.data) return null;

        const whatsapp = this.props.data.whatsapp;
        const instagram = this.props.data.instagram_ce;
        const facebook = this.props.data.facebook_ce;
        
        const logo_whatsapp = "images/" + this.props.data.loghi.whatsapp;
        const logo_instagram = "images/" + this.props.data.loghi.instagram;
        const logo_facebook = "images/" + this.props.data.loghi.facebook;

        const file_programma = "file/Programma_Centro_Estivo_2022.pdf";
        const file_iscrizione = "file/Modulo_Iscrizione_Centro_Estivo_2022.pdf";

        return (
            <section id="contatti" style={{background:"#f9b235", backgroundSize:"cover" }}>
                <div className="row">
                    <Slide right duration={1000}>
                        <div className="row">
                            < div className="align-center">
                                <h1>
                                    <span style={{ color: "white" }} className="shadowtext-1b">CONTATTI</span>
                                </h1>
                            </div>
                        </div>

                        <Fade bottom>
                            <ul className="social-links text-center" >
                                <h3 className="shadowtext-1bl"> Marco: 347 512 4125</h3>
                                <a className="social-links" target="blank" style={{ marginRight: "15px" }} href={whatsapp}>
                                    <img alt="whatsapp" src={logo_whatsapp} style={{ width: "45px" }} />
                                </a>
                                <a className="social-links" target="blank" style={{ marginRight: "15px" }} href={instagram}>
                                        <img alt="instagram" src={logo_instagram} style={{ width: "45px" }} />
                                    </a>
                                    <a target="blank" style={{ marginRight: "15px" }} href={facebook}>
                                        <img alt="facebook" src={logo_facebook} style={{ width: "45px" }} />
                                    </a>
                            </ul>
                        </Fade>
                    </Slide>
                </div>
            </section>
        );
    }
}

export default Info;
