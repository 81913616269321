export const Programma_section1 = () => {
    return (
        <div>
            <h2 className="shadowtext-1b" style={{color:"#f6a000", paddingBottom:"10px"}}>1° SETTIMANA</h2>
            <h3>―――――<b className="iconActivity2">Lun</b>―――――</h3>
            <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Palestra Sport Mix Due <br/>
            <text className="shadowtext-1b">Pranzo al sacco </text><br/>
            <text className="shadowtext-1b">Pomeriggio:</text> Campo Martelli</h3>
            <p>Consegna e ritiro bambini al Palazzetto dello Sport</p>

            <h3>―――――<b className="iconActivity2">Mar</b>―――――</h3>
            <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text>Pineta; attività con i cani<br/>
            <text className="shadowtext-1b">Pranzo: </text> Garden Club<br/>
            <text className="shadowtext-1b">Pomeriggio:</text> Cambusiero</h3>
            <p>Consegna e ritiro bambini al Garden Club</p>
        
            <h3>―――――<b className="iconActivity2">Mer</b>―――――</h3>
            <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Palestra Sport Mix Due; English Games <br/>
            <text className="shadowtext-1b">Pranzo al sacco </text><br/>
            <text className="shadowtext-1b">Pomeriggio:</text> Campo Martelli</h3>
            <p>Consegna e ritiro bambini al Palazzetto dello Sport</p>
        
            <h3>―――――<b className="iconActivity2">Gio</b>―――――</h3>
            <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Pineta<br/>
            <text className="shadowtext-1b">Pranzo al sacco </text><br/>
            <text className="shadowtext-1b">Pomeriggio:</text> Cambusiero</h3>
            <p>Consegna e ritiro bambini al Garden Club</p>
        
            <h3>―――――<b className="iconActivity2">Ven</b>―――――</h3>
            <h3 className="shadowtext-1g"><text className="shadowtext-1b">Mattina:</text> Centro ippico Ruben Ranch<br/>
            <text className="shadowtext-1b">Pranzo al sacco </text><br/>
            <text className="shadowtext-1b">Pomeriggio:</text> Mare</h3>
            <p>Consegna bambini al Centro ippico Ruben Ranch<br/>
            Ritiro bambini "da comunicare"</p>
            <br />
        </div>
    )
}
