import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";

class Prenota extends Component {
  render() {
    if (!this.props.data) return null;


    const hiphop_desc = this.props.data.ordinary_lab.hiphop_desc;
    const hiphop_photo = "images/" + this.props.data.ordinary_lab.choice_hip_hop;

    const logo_email = "images/" + this.props.data.loghi.email;
    const email = "mailto:" + this.props.data.ordinary_lab.mail;
    const logo_whatsapp = "images/" + this.props.data.loghi.whatsapp;
    const whatsapp = this.props.data.whatsapp;
    const telegram = this.props.data.telegram;
    const logo_telegram = "images/" + this.props.data.loghi.telegram;

    return (
      <section id="info" style={{ background: "url(images/ordinarylab/lezione.png)", backgroundSize: "cover" }}>
        <div className="row">
          <Slide right duration={1000}>
            <div className="row">
              < div className="align-center">
                <h1>
                  <span style={{ color: "white" }} className="shadowtext-1b"><u>PRENOTA</u></span>
                </h1>
              </div>
            </div>

            <Fade bottom>
              <div className="align-center prenotaBox" >
                <h3 style={{"text-shadow": "1px 1px black"}}>Se vuoi avere più informazioni <br />o se vuoi prenotare il percorso Ordinary House Lab nella tua scuola,
                  <br />CONTATTACI!</h3>
                <br />
                <a target="blank" style={{ marginRight: "50px" }} href={email}>
                  <img alt="email" src={logo_email} style={{ width: "45px" }} />
                </a>
                <a className="social-links" target="blank" style={{ marginRight: "50px" }} href={whatsapp}>
                  <img alt="whatsapp" src={logo_whatsapp} style={{ width: "45px" }} />
                </a>
                <a target="blank" style={{ marginRight: "15px" }} href={telegram}>
                  <img alt="telegram" src={logo_telegram} style={{ width: "45px" }} />
                </a>
              </div>
            </Fade>

          </Slide>
        </div>


       
      </section>
    );
  }
}

export default Prenota;
