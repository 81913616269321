import React, { Component } from "react";
import Fade from "react-reveal";
import { ZoomImage } from "../ZoomImage";
import Collapse from 'react-bootstrap/Collapse'


class Workshop extends Component {
    constructor(props) {
        super(props);

        this.state = {
            collapseMenu: true,
            isOpen: false
        };

        this.showHide = this.showHide.bind(this);
    }

    showHide(e) {
        e.preventDefault();

        this.setState({
            collapseMenu: !this.state.collapseMenu
        });
    }


    handleShowDialog = () => {
        this.setState({ isOpen: !this.state.isOpen });
        console.log("cliked");
    };

    render() {
        if (!this.props.data) return null;

        const img_stage = "images/" + this.props.data.stage.img_riquadro_iscrizioni;

        return (
            <section id="workshop" >
                <Fade duration={1000}>
                    <div className="row">
                        < div className="align-center">
                            <h1>
                                <span style={{ color: "white" }}>Cypher Workshop</span>
                            </h1>
                        </div><br />
                    </div>
                    <div className="row">
                        <div className="four columns">
                            <div id="iscrizione_div" className="align-center">
                                <ZoomImage className="img_iscrizioni" title="" largeImage={img_stage} smallImage={img_stage} />

                            </div>
                        </div><div className="eight columns main-col info-event" style={{ paddingLeft: "30px" }}>

                            <button
                                onClick={this.showHide}
                                className="btn btn-outline-primary btn-lg"
                                type="button"
                                aria-expanded="false"
                            >
                                Come Funziona?
                            </button>

                            <Collapse in={!this.state.collapseMenu}>
                                <div className="card card-body">
                                    <p style={{lineHeight: "30px"}}>
                                        <b className="iconText">⏱️</b> 3 ore di lezione<br />
                                        <b className="iconText">🎵</b> Dj set di Dj Collo<br />
                                        <b className="iconText">⭕</b> I partecipanti potranno seguire le lezioni formando 4 cerchi<br />
                                        <b className="iconText">⏳</b> Ogni 45 minuti il dj cambierà genere musicale, gli insegnanti cambieranno argomento tecnico e i ballerini potranno scegliere in quale cypher proseguire la lezione<br />
                                        <b className="iconText">📒</b> In questo modo ogni partecipante al workshop potrà scegliere il proprio percorso, andando a studiare ciò che più gli interessa.<br /><br />
                                        <b className="iconText">🎵</b> Questi saranno i generi suonati da Dj Collo:
                                        <br/>
                                        - Jazzy Vibes<br/>
                                        - Boombap<br/>
                                        - Beat<br/>
                                        - Nu school rap<br/>
                                        <br></br>
                                        <b className="iconText">📝</b> Questi saranno gli argomenti proposti dagli insegnanti:<br />
                                        </p>
                                        <div id="workshopList" className="row" style={{ paddingTop: "10px", color:"white" }}>
                                            <div className="three columns"><b>Wally:</b>
                                            <ul><li>Jazzy Vibes: concetto di infinito</li>
                                                <li>Boombap: groove</li>
                                                <li>Beat: scala mobile</li>
                                                <li>Nu School Rap: ritmiche</li>
                                            </ul></div>
                                            <div className="three columns"><b>Babi:</b>
                                                <ul><li>Jazzy Vibes: musicalità</li>
                                                    <li>Boombap: direzioni</li>
                                                    <li>Beat: floorwork</li>
                                                    <li>Nu School Rap: voce</li>
                                                </ul></div>
                                            <div className="three columns"><b>Ruben:</b>
                                                <ul><li>Jazzy Vibes: feeling</li>
                                                    <li>Boombap: bounce</li>
                                                    <li>Beat: musicalità</li>
                                                    <li>Nu School Rap: attitudine</li>
                                                </ul></div>
                                            <div className="three columns"><b>Dange:</b>
                                                <ul><li>Jazzy Vibes: respirazione e struttura</li>
                                                    <li>Boombap: isolazioni</li>
                                                    <li>Beat: braccia</li>
                                                    <li>Nu School Rap: interpretazione</li>
                                                </ul></div></div>

                                        <table id="workshopTable">
                                            <thead>
                                            <tr>
                                                <th></th>
                                                <th>Wally</th>
                                                <th>Babi</th>
                                                <th>Ruben</th>
                                                <th>Dange</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>Jazzy Vibes</td>
                                                <td>Concetto di infinito</td>
                                                <td>Musicalità</td>
                                                <td>Feeling</td>
                                                <td>Respirazione e struttura</td>
                                            </tr>
                                            <tr>
                                                <td>Boombap</td>
                                                <td>Groove</td>
                                                <td>Direzioni</td>
                                                <td>Bounce</td>
                                                <td>Isolazioni</td>
                                            </tr>
                                            <tr>
                                                <td>Beat</td>
                                                <td>Scala mobile</td>
                                                <td>Floorwork</td>
                                                <td>Musicalità</td>
                                                <td>Braccia</td>
                                            </tr>
                                            <tr>
                                                <td>Nu School Rap</td>
                                                <td>Ritmiche</td>
                                                <td>Voce</td>
                                                <td>Attitudine</td>
                                                <td>Intepretazione</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                                       </div>
                            </Collapse>


                            <div className="row">
                                <div className="columns contact-details">
                                    <h2>Info e Iscrizione</h2>
                                    <p className="address">
                                        <span><b>Orario Inizio:</b><span className="disclaimer">* </span>15:00</span><br />
                                        
                                       {/* <a style={{ paddingLeft: "0%" }} href="/iscrizioni_stage"><button style={{ backgroundColor: "#115394", marginTop: "20px" }}>Richiesta di partecipazione al Workshop</button></a>  */}
                                     
                                        <br />
                                        <span><b className="iconText">📍</b> <b>Palazzetto dello Sport di Montalto di Castro </b><br/> Via dell'Arcipretura, 01014 S.s. Aurelia-località Arcipretura VT, Italia </span>
                                        <br /><br /><br />
                                        <span className="disclaimer" style={{ fontSize: "12px" }}>* Gli orari potrebbero subire variazioni che saranno tempestivamente comunicate al contatto lasciato nel form di iscrizione</span>

                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </section >
        );
    }
}

export default Workshop;
