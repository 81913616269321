export const Attivita_section4 = () => {
    return (
        <div>
            <h3> <img src={"images/centroestivo/loghi/logo_masa.png"} width="35%" style={{"margin-top":"-30px"}}/> </h3><h3 className="shadowtext-1bl">Riva Trekking</h3>
            <br />
            <h3> <img src={"images/centroestivo/loghi/logo_sportmix2.png"} width="25%"/> </h3><h3 className="shadowtext-1bl">English Games</h3>
            <br />
            <h3> <img src={"images/centroestivo/loghi/logo_pasticceria.jpeg"} width="20%"/> </h3><h3 className="shadowtext-1bl">Pasticciando</h3>
            <h6 className="new-badge align-center">NEW</h6>
        </div>
    )
}